/*------------------------------------------------------------------
[TABLE OF CONTENTS]

1. BASE

  1.1. Typography
  1.2. Buttons
  1.3. Common Styles

    1.3.1. Preloader
    1.3.2. Section
    1.3.3. Background Image
    1.3.4. Overlay
    1.3.5. Common Class
    1.3.6. Icon Animation
    1.3.7. Breadcrumb
    1.3.8. Google Map
    1.3.9. Form Control
    1.3.10. Social Icon
    1.3.11. Slick Slider

2. NAVBAR

3. BANNER SLIDER

4. HOMEPAGE

  4.1. Banner Feature / Service Item
  4.2. About Section
  4.3. Funfacts Section
  4.4. Feature Section
  4.5. Service Section
  4.6. Team Section
  4.7. Project Section
  4.8. Testimonial Section

5. CLIENTS LOGO

6. CALL TO ACTION

7. PRICING

8. BLOG

9. FOOTER STYLES

-------------------------------------------------------------------*/
/* bootstrap Icon  */
/*  typography */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dongle:wght@300&family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&display=swap");

:root {
  --main-color: rgb(64, 21, 254);
  --main-bg-color: #3000b4;
  --main-bg-trans-color: #f4b357e1;
  --main-bg-trans-color-2: #f4b35786;
  --second-color: #6648ab;
  --primary-font: "Rubik", sans-serif;
  --secondary-font: "Work Sans", sans-serif;
  --tertiary-font: "Inter", sans-serif;
}

* {
  font-family: var(--primary-font);
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  padding: 0;
  margin: 0;
}

p,
.paragraph {
  color: #222223;
  font-size: 18px;
  line-height: 1.3;
}

li {
  color: #3e3e3f;
  font-size: 17px;
}

.bg-danger {
  background-color: #e54220;
}

.bg-light-orange {
  background-color: rgb(255, 149, 2, 0.2);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #172231;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.2;
  transition: 0.2s ease;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1,
.h1 {
  font-size: 50px;
}

h2,
.h2 {
  font-size: 48px;
}

h3,
.h3 {
  font-size: 44px;
}

h4,
.h4,
.subtitle {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

/* /typography */
/* Button style */
.btn {
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 0px;
  border: 1px solid;
  position: relative;
  z-index: 1;
  transition: 0.3s ease-in;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn-lg {
  font-size: 20px;
  padding: 19px 35px;
}

.btn-md {
  font-size: 18px;
  padding: 17px 30px;
}

.btn-sm {
  font-size: 12px;
  padding: 5px 20px;
}

.btn-primary {
  background: var(--main-color);
  color: #fff;
  border-color: var(--main-color);
}

.btn-primary:active {
  background: var(--main-bg-color) !important;
  border-color: var(--main-bg-color);
}

.btn-primary:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-gray {
  background: #f5f6fa;
  color: #172231;
  border-color: #f5f6fa;
}

.btn-gray:active {
  background: var(--main-color) !important;
  border-color: var(--main-color);
  color: #fff;
}

.btn-gray:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-secondary {
  background: transparent;
  color: #172231;
  padding-left: 0;
  border: 0;
}

.btn-secondary:active {
  background: transparent !important;
  color: var(--main-color);
}

.btn-secondary:hover {
  background: transparent;
  color: var(--main-color);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--main-color);
  background-color: transparent;
  border: 0;
}

.btn-primary-outline {
  background: transparent;
  color: #172231;
  border: 2px solid #172231;
}

.btn-primary-outline:active {
  background: var(--main-color) !important;
  border-color: var(--main-color);
  color: #fff;
}

.btn-primary-outline:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

.features .hover-bg-dark:hover .btn-primary-outline {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.features .hover-bg-dark:hover .btn-primary-outline:active {
  background: var(--main-bg-color) !important;
  border-color: var(--main-bg-color);
  color: #fff;
}

.features .hover-bg-dark:hover .btn-primary-outline:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #fff;
}

.btn-light {
  background: #fff;
  color: #172231;
  border-color: #e7e7e7;
}

.btn-light:active {
  background: transparent !important;
  border-color: #fff;
  color: #fff;
}

.btn-light:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-light-alt:active {
  background: var(--main-color) !important;
  border-color: var(--main-color);
  color: #fff;
}

.btn-light-alt:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-light-outline {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.btn-light-outline:active {
  background: var(--main-bg-color) !important;
  border-color: var(--main-bg-color);
  color: #fff;
}

.btn-light-outline:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #fff;
}

.btn-arrow {
  transition: 0.2s ease;
}

.btn-arrow::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  left: calc(85% + 5px);
  transition: 0.2s ease;
}

.btn-arrow::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: #000;
  transition: 0.2s ease;
  left: 85%;
}

.btn-arrow:hover::before {
  left: calc(85% + 10px);
  border-color: var(--main-color);
}

.btn-arrow:hover::after {
  width: 20px;
  background: var(--main-color);
}

/* hover effect */
.hover-ripple {
  transition: 0.2s ease-in;
}

.hover-ripple:hover {
  transform: scale(0.97);
}

.hover-ripple:hover::after {
  -webkit-animation: sonarEffect 1s ease-out 50ms;
  animation: sonarEffect 1s ease-out 50ms;
}

.hover-ripple::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  opacity: 0;
  transform: scale(0.9);
  border-radius: inherit;
}

.hover-ripple.ripple-white:hover::after {
  -webkit-animation: sonarEffectWhite 1s ease-out 50ms;
  animation: sonarEffectWhite 1s ease-out 50ms;
}

@-webkit-keyframes sonarEffect {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes sonarEffect {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1),
      0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@-webkit-keyframes sonarEffectWhite {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff,
      0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff,
      0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes sonarEffectWhite {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff,
      0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff,
      0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

/* /button style */
body {
  background-color: #fff;
  overflow-x: hidden;
}

::-moz-selection {
  background: #9ed060;
  color: #fff;
}

::selection {
  background: #9ed060;
  color: #fff;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  height: 50px;
  width: 50px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(134, 195, 58, 0.75) rgba(134, 195, 58, 0.75)
    rgba(134, 195, 58, 0.25) rgba(134, 195, 58, 0.25);
  border-radius: 100%;
  -webkit-animation: clockwise 1s linear infinite;
  animation: clockwise 1s linear infinite;
}

@-webkit-keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}

@keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}

/* /preloader */
ol,
ul {
  list-style-type: none;
  margin: 0px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: 0.2s ease;
}

a:focus,
button:focus,
select:focus {
  outline: 0;
}

a:hover {
  color: var(--main-color);
}

a.text-white:hover,
a.text-light:hover,
a.text-dark:hover {
  color: var(--main-color) !important;
}

/* ================
Icon Sizes 
================ */
.icon-25 {
  height: 25px;
  width: 25px;
  max-width: 100%;
  object-fit: contain;
}

.icon-50 {
  height: 50px;
  width: 50px;
  max-width: 100%;
  object-fit: contain;
}

.icon-75 {
  height: 75px;
  width: 75px;
  max-width: 100%;
  object-fit: contain;
}

.icon-100 {
  height: 100px;
  width: 100px;
  max-width: 100%;
  object-fit: contain;
}

.icon-125 {
  height: 125px;
  width: 125px;
  max-width: 100%;
  object-fit: contain;
}

.icon-150 {
  height: 150px;
  width: 150px;
  max-width: 100%;
  object-fit: contain;
}

.icon-175 {
  height: 175px;
  width: 175px;
  max-width: 100%;
  object-fit: contain;
}

.icon-200 {
  height: 200px;
  width: 200px;
  max-width: 100%;
  object-fit: contain;
}

.icon-225 {
  height: 225px;
  width: 225px;
  max-width: 100%;
  object-fit: contain;
}

.icon-250 {
  height: 250px;
  width: 250px;
  max-width: 100%;
  object-fit: contain;
}

.icon-275 {
  height: 275px;
  width: 275px;
  max-width: 100%;
  object-fit: contain;
}

.icon-300 {
  height: 300px;
  width: 300px;
  max-width: 100%;
  object-fit: contain;
}

/* ================
Widths 
================ */
.width-25 {
  width: 25px;
}

.width-50 {
  width: 50px;
}

.width-60 {
  width: 50px;
}

.width-75 {
  width: 75px;
}

.width-100 {
  width: 100px;
}

.width-125 {
  width: 125px;
}

.width-150 {
  width: 150px;
}

.width-175 {
  width: 175px;
}

.width-200 {
  width: 200px;
}

.width-225 {
  width: 225px;
}

.width-250 {
  width: 250px;
}

.width-275 {
  width: 275px;
}

.width-300 {
  width: 300px;
}

/* ================
Heights 
================ */
.height-25 {
  height: 25px;
}

.height-50 {
  height: 50px;
}

.height-60 {
  height: 60px;
}

.height-65 {
  height: 65px;
}

.height-75 {
  height: 75px;
}

.height-100 {
  height: 100px;
}

.height-125 {
  height: 125px;
}

.height-150 {
  height: 150px;
}

.height-175 {
  height: 175px;
}

.height-200 {
  height: 200px;
}

.height-225 {
  height: 225px;
}

.height-250 {
  height: 250px;
}

.height-275 {
  height: 275px;
}

.height-300 {
  height: 300px;
  object-fit: contain;
}

.height-400 {
  height: 400px;
  object-fit: contain;
}

.height-500 {
  height: 500px;
  object-fit: contain;
}

.fit-cover {
  object-fit: cover;
}

/* ================
Padding 
================ */
.padt-25 {
  padding-top: 25px;
}

.padt-50 {
  padding-top: 50px;
}

.padt-75 {
  padding-top: 75px;
}

.padt-100 {
  padding-top: 100px;
}

.padt-125 {
  padding-top: 125px;
}

.padt-150 {
  padding-top: 150px;
}

.padt-175 {
  padding-top: 175px;
}

.padt-200 {
  padding-top: 200px;
}

.padt-225 {
  padding-top: 225px;
}

.padt-250 {
  padding-top: 250px;
}

.padt-275 {
  padding-top: 275px;
}

.padt-300 {
  padding-top: 300px;
}

.padb-25 {
  padding-bottom: 25px;
}

.padb-50 {
  padding-bottom: 50px;
}

.padb-75 {
  padding-bottom: 75px;
}

.padb-100 {
  padding-bottom: 100px;
}

.padb-125 {
  padding-bottom: 125px;
}

.padb-150 {
  padding-bottom: 150px;
}

.padb-175 {
  padding-bottom: 175px;
}

.padb-200 {
  padding-bottom: 200px;
}

.padb-225 {
  padding-bottom: 225px;
}

.padb-250 {
  padding-bottom: 250px;
}

.padb-275 {
  padding-bottom: 275px;
}

.padb-300 {
  padding-bottom: 300px;
}

/* ================
Fonts 
================ */
.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-30 {
  font-size: 30px;
}

.font-40 {
  font-size: 40px;
}

.font-50 {
  font-size: 50px;
}

.font-60 {
  font-size: 60px;
}

.font-70 {
  font-size: 70px;
}

.font-80 {
  font-size: 80px;
}

.font-90 {
  font-size: 90px;
}

.font-100 {
  font-size: 100px;
}

.font-110 {
  font-size: 110px;
}

.font-120 {
  font-size: 120px;
}

.font-130 {
  font-size: 130px;
}

.font-140 {
  font-size: 140px;
}

.font-150 {
  font-size: 150px;
}

.font-160 {
  font-size: 160px;
}

/* section */
.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .infaSlider {
    width: 500px;
  }

  .infaSliderTwo {
    width: 500px;
    margin: auto;
  }
}

@media (max-width: 575px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .club-right-banner {
    border-radius: 10px !important;
    height: 200px !important;
    box-shadow: 0 0 10px silver;
    object-fit: cover;
    object-position: center;
  }

  .infaSlider {
    width: 300px;
  }

  .infaSliderTwo {
    width: 300px;
    margin: auto;
  }
}

.section-sm {
  padding-top: 70px;
  padding-bottom: 70px;
}

.section-title {
  margin-bottom: 40px;
}

.section-title-border {
  position: relative;
  height: 5px;
  width: 30px;
  background: var(--main-color);
  border-radius: 10px;
  margin-bottom: 20px;
  display: block;
}

.section-title-border::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: var(--main-color);
  border-radius: 10px;
  right: -60%;
  top: 0;
}

.section-title-border::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 25%;
  background: var(--main-color);
  border-radius: 10px;
  right: -100%;
  top: 0;
}

.section-title-border.border-center {
  margin-left: calc(50% - 27px);
}

.section-title-border.border-white {
  background: #fff;
}

.section-title-border.border-white::before {
  background: #fff;
}

.section-title-border.border-white::after {
  background: #fff;
}

.section-title-border.border-inline {
  display: inline-block;
  margin-bottom: 5px;
}

.subtitle {
  font-weight: 600;
  color: #818385;
}

.section-bottom-lg {
  padding-bottom: 240px;
}

/* /section */
/* background image */
.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
  .bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .display-none {
    display: none !important;
  }
}

.bg-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.bg-inset-75 {
  box-shadow: inset 0 0 0 2000px rgba(8, 8, 8, 0.726);
}

/* /background image */
/* overlay */
.overlay {
  position: relative;
}

.overlay::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.6;
}

.overlay-secondary {
  position: relative;
}

.overlay-secondary::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--second-color);
  opacity: 0.85;
}

/* /overlay */
/* common class */
.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: var(--main-color) !important;
}

.bg-secondary {
  background: var(--second-color) !important;
}

.bg-secondary-darken {
  background: #1f2630 !important;
}

.bg-gray {
  background: #f5f6fa !important;
}

.bg-gray-white {
  background-image: linear-gradient(to right, #f5f6fa 45%, #fff 0%);
}

.bg-white-gray {
  background-image: linear-gradient(to right, #fff 45%, #f5f6fa 0%);
}

.bg-white2-gray {
  background-image: linear-gradient(to right, #fff 70%, #f5f6fa 0%);
}

.bg-white3-gray {
  background-image: linear-gradient(to right, #fff 63%, #f5f6fa 0%);
}

.bg-dark-gray {
  background-image: linear-gradient(to right, #ffb45d 40%, #ffffff 0%);
}

.text-primary {
  color: var(--main-color) !important;
}

.text-color {
  color: #e0e1e2;
}

.text-light {
  color: rgb(244, 242, 242) !important;
}

.text-dark {
  color: #172231;
}

.hilighted {
  background: var(--second-color);
  padding: 0 5px;
  border-radius: 2px;
  color: #fff;
}

.letter-spacing {
  letter-spacing: 2px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.zindex-0 {
  z-index: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.min-height-400 {
  min-height: 400px;
}

.border-color {
  border-color: #e7e7e7 !important;
}

.border-muted {
  border-color: #404751 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.flex-basis-33 {
  flex-basis: 33.333333%;
}

.icon {
  font-size: 40px;
}

.icon-sm {
  font-size: 34px;
}

.icon-xs {
  font-size: 22px;
}

.icon-lg {
  font-size: 56px;
}

.box-shadow {
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.translate-top {
  transform: translateY(-100px);
}

.transition {
  transition: 0.2s ease;
}

.font-size-30 {
  font-size: 30px;
}

.font-weight-semebold {
  font-weight: 600 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-none:hover {
  text-decoration: none !important;
}

.border-md-right {
  border-right: 1px solid;
}

@media (max-width: 767px) {
  .border-md-right {
    border: 0;
  }
}

/* /common class */
/* icon animation */
.icon-bg {
  position: relative;
  background-color: #01d95b98;
  display: inline-block;
  height: 100px;
  width: 100px;
  box-shadow: 0 0 40px #01d95a;
  border-radius: 120px 135px 110px 90px;
}

.icon-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 5px;
  background: rgba(134, 195, 58, 0.05);
  border: 2px solid var(--main-color);
  border-radius: 130px 120px 160px 130px;
}

.icon-bg .icon {
  line-height: 100px;
}

.water-wave {
  position: relative;
  -webkit-animation-name: water-wave;
  animation-name: water-wave;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.water-wave::before {
  -webkit-animation-name: water-wave;
  animation-name: water-wave;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes water-wave {
  0% {
    border-radius: 120px 135px 110px 90px;
  }

  25% {
    border-radius: 130px 140px 100px 110px;
  }

  50% {
    border-radius: 110px 97px 150px 100px;
  }

  75% {
    border-radius: 80px 107px 120px 90px;
  }

  100% {
    border-radius: 120px 135px 110px 90px;
  }
}

@keyframes water-wave {
  0% {
    border-radius: 120px 135px 110px 90px;
  }

  25% {
    border-radius: 130px 140px 100px 110px;
  }

  50% {
    border-radius: 110px 97px 150px 100px;
  }

  75% {
    border-radius: 80px 107px 120px 90px;
  }

  100% {
    border-radius: 120px 135px 110px 90px;
  }
}

/* google map */
#map_canvas {
  height: 500px;
}

/* form control */
.form-control {
  height: 55px;
  padding: 3px 5px;
  border-radius: 0px;
}

.form-control-sm {
  height: 45px;
}

.form-control:focus {
  box-shadow: 5px 5px 0px 0px var(--main-bg-trans-color-2);
  border-color: var(--main-color) !important;
}

.focus-shadow-none {
  box-shadow: none !important;
}

textarea.form-control {
  height: 150px;
}

textarea.form-control-sm {
  height: 100px;
}

/* /form control */
/* social icon */
.social-icon li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  line-height: 45px;
  text-align: center;
}

.social-icon li a:hover {
  color: var(--main-color);
  background: #fff;
}

.social-icon-alt li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  line-height: 45px;
  text-align: center;
}

.social-icon-alt li a:hover {
  color: #fff;
  background: var(--main-color);
  border-color: var(--main-color);
}

/* /social icon */
/* slick style */
.slick-slide {
  outline: 0;
}

/* slick arrows */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: rgba(134, 195, 58, 0.5);
  color: #fff;
  border: 0;
  line-height: 70px;
  font-size: 35px;
}

.slick-arrow::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: 0.2s ease;
}

.slick-arrow::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 25px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  transition: 0.2s ease;
}

@media (max-width: 575px) {
  .slick-arrow {
    display: none;
  }
}

.slick-arrow:focus {
  outline: 0;
}

.slick-arrow:hover {
  background: var(--main-color);
}

.prevArrow {
  left: 0px;
}

.prevArrow::before {
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  right: 35px;
}

.prevArrow::after {
  right: 20px;
}

.nextArrow {
  right: 0px;
}

.nextArrow::before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  left: 35px;
}

.nextArrow::after {
  left: 20px;
}

/* /slick arrows */
/* slick dots */
.slick-dots {
  padding-left: 0;
  text-align: center;
  position: absolute;
  bottom: -185px;
  left: 0;
  right: 0;
  z-index: 9;
}

.slick-dots li {
  display: inline-block;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 20px;
  transition: 0.2s ease;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.slick-dots li img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.slick-dots li.slick-active {
  transform: scale(1.2);
}

/* /slick dots */
/* Navbar */
/* top header */
.top-header {
  font-size: 14px;
}

/* /top header */
/* navigation */
.navigation {
  box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.09);
  /* position: relative; */
  z-index: 1;
}

@media (min-width: 992px) {
  .navigation {
    height: 110px;
    display: flex;
    align-items: center;
  }
}

.navbar {
  padding: 0;
}

.navbar-collapse {
  transition: 0.2s ease;
}

.navbar .nav-item .nav-link {
  text-transform: uppercase;
  font-weight: 700;
}

.navbar .nav-item .navbar-title {
  font-size: 13px;
  color: #3f2200;
}

.navbar .nav-item .navbar-title:hover {
  color: var(--main-color);
}

.navbar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1);
}

.navbar .dropdown-menu {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  padding: 25px 15px;
  border: 0;
  top: 45px;
  right: 0px;
  border-radius: 15;
  display: block;
  visibility: hidden;
  transition: 0.3s ease;
  opacity: 0;
  transform: scale(0.8);
  background: #fff;
}

.registerBtnNav {
  border: 1px solid #8000ff !important;
  color: #8000ff !important;
  min-width: 100px !important;
}

.loginBtnNav {
  font-size: 18px !important;
  background-color: #8000ff !important;
  min-width: 100px !important;
}

.loginBtnNav:hover {
  background-color: #8000ff !important;
}

@media (max-width: 700px) {
  .navbar .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: unset;
  }
}

.navbar .dropdown-menu.show {
  visibility: hidden;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu.show {
    visibility: visible;
    display: block;
  }
}

.navbar .dropdown-item {
  position: relative;
  color: #172231;
  transition: 0.2s ease;
}

@media (max-width: 700px) {
  .navbar .dropdown-item {
    text-align: center;
  }
}

.spc {
  color: lightblue;
}

@media screen and (min-width: 600px) {
  .navbar .dropdown-item {
    position: relative;
    color: #172231;
    transition: 0.2s ease;
  }

  .text-md-justify {
    text-align: justify;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    /* color: var(--bs-dropdown-link-active-color); */
    color: #bb7006;
    text-decoration: none;
    background-color: transparent;
  }
}

.navbar .dropdown-item:hover {
  color: var(--main-color);
  background: transparent;
}

.scrolled .navbar-light .navbar-nav .nav-link {
  color: #172231;
}

.scrolled .navbar-light .navbar-nav .nav-link:hover {
  color: var(--main-color);
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--main-color);
}

.navbar-brand {
  width: 20% !important;
}

div.inputField {
  background-color: #f3f3f3;
}

div.inputField #search {
  border: none;
  background-color: #f3f3f3;
}

@media screen and (max-width: 500px) {
  .navbar-brand > img {
    height: 60px;
  }

  .btn {
    padding: 9px 6px;
  }
}

/* search */
.search-btn {
  border: 0;
  background: transparent;
}

.search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  transition: 0.2s ease;
  opacity: 0;
}

.search-wrapper.open {
  visibility: visible;
  opacity: 1;
}

.search-box {
  height: 110px;
  width: 100%;
  border: 0;
  background: #fff;
  font-size: 30px;
  padding: 0;
}

@media (min-width: 992px) {
  .search-box {
    margin-top: -3px;
  }
}

.search-box:focus {
  box-shadow: none !important;
}

.search-close {
  position: absolute;
  right: 5px;
  top: 40px;
  border: 0;
  background: transparent;
  page-break-after: 10px;
  font-size: 20px;
}

/* /navigation */
/* hero slider */
.hero-section {
  padding: 70px 0 70px;
}

@media (max-width: 767px) {
  .hero-section {
    padding: 20px 0 20px;
  }
}

.hero-slider {
  overflow-x: hidden;
}

.hero-slider .prevArrow {
  left: -100px;
}

.hero-slider .nextArrow {
  right: -100px;
}

.hero-slider:hover .prevArrow {
  left: 35px;
}

.hero-slider:hover .nextArrow {
  right: 35px;
}

/* /hero slider */
/* banner feature */
.banner-feature-wrapper {
  transform: translateY(-100px);
}

.hover-bg-dark,
.hover-bg-dark {
  transition: 0.5s;
}

.hover-bg-dark:hover,
.hover-bg-dark.active {
  background: rgb(65, 3, 56);
  background: radial-gradient(
    circle,
    rgb(0, 0, 59) 50%,
    rgba(1, 0, 39, 1) 100%
  );
}

.hover-bg-dark:hover *,
.hover-bg-dark.active * {
  color: #fff !important;
}

/* /banner feature */
/* about */
.image-bg {
  position: absolute;
  top: 20%;
  right: 0;
  z-index: -1;
}

.about-bg-shape {
  position: absolute;
  top: 0;
  right: 0;
}

.philosophy-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}

/* /about */
/* funfacts */
.funfacts {
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .funfacts {
    background: var(--second-color);
  }
}

.funfacts .container {
  transform: translateX(-50px);
  position: relative;
}

.funfacts .container::before {
  position: absolute;
  content: "";
  right: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--second-color);
}

@media (max-width: 991px) {
  .funfacts .container {
    transform: translate(0);
  }

  .funfacts .container::before {
    display: none;
  }
}

@media (max-width: 991px) {
  .funfacts h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .funfacts h3 {
    font-size: 35px;
  }
}

@media (max-width: 991px) {
  .funfacts .icon-lg {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .funfacts .icon-lg {
    font-size: 50px;
  }
}

/* /funfacts */
/* feature */
.feature-img-bg {
  position: relative;
}

.feature-img-bg::before {
  position: absolute;
  content: "";
  background: url(../../public/images/backgrounds/feature-bg.png);
  top: -100px;
  right: -120px;
  height: 150%;
  width: 120%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 991px) {
  .feature-img-bg::before {
    display: none;
  }
}

.play-video {
  position: absolute;
  left: 0;
  bottom: 20%;
}

.play-icon {
  display: inline-block;
  min-width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  font-size: 30px;
}

/* /feature */
/* service */
.service-wrapper {
  transform: translateY(-200px);
}

.card-icon {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: var(--main-color);
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 32px;
  left: 15px;
  top: -90px;
}

.service-bg-dots {
  position: relative;
}

.service-bg-dots::before {
  position: absolute;
  content: "";
  background: url(../../public/images/backgrounds/service-page-2.png);
  top: 0px;
  right: 0px;
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

/* /service */
/* team */
.team-member-img img {
  position: relative;
  z-index: 2;
}

.team-member-img::before {
  position: absolute;
  content: "";
  background: url(../../public/images/backgrounds/team-member-bg.png);
  top: 0px;
  right: 0px;
  height: 60%;
  width: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  transition: 0.2s ease;
}

.team-member-img::after {
  position: absolute;
  content: "";
  background: url(../../public/images/backgrounds/team-member-bg.png);
  bottom: -40px;
  left: 0px;
  height: 60%;
  width: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  transition: 0.2s ease;
}

.team-member:hover .team-member-img::before {
  top: -20px;
  right: -20px;
}

.team-member:hover .team-member-img::after {
  bottom: -60px;
  left: -20px;
}

.team-member:hover .hover-icon {
  transform: scale(1);
  opacity: 1;
}

.team-member:hover .hover-icon .social-icon a {
  transform: scale(1);
}

.hover-icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--main-bg-trans-color);
  transition: 0.2s ease;
  transform: scale(0.8);
  z-index: 3;
  opacity: 0;
}

.hover-icon .social-icon a {
  transform: scale(0);
}

/* /team */
/* project */
.project-item img {
  transition: 0.4s ease-in;
}

@media (max-width: 767px) {
  .project-item img {
    margin-bottom: -60px;
  }
}

.project-item:hover img {
  transform: scale(1.05);
}

.project-item:hover .hover-overlay {
  opacity: 1;
}

.project-item:hover .hover-overlay a {
  transform: translateY(-50%) scale(1);
}

.hover-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: var(--main-bg-trans-color);
  transition: 0.4s ease;
  opacity: 0;
}

.hover-overlay a {
  position: relative;
  font-size: 46px;
  color: #fff;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: 0.4s ease;
  display: inline-block;
}

.uni-plus {
  position: relative;
  padding: 0 20px;
}

.uni-plus::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 2px;
  background: #fff;
}

.uni-plus::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
  width: 40px;
  background: #fff;
}

.filter-controls li {
  cursor: pointer;
}

.filter-controls li.active {
  color: var(--main-color) !important;
}

/* /project */
/* testimonial */
.bg-dots {
  position: relative;
}

.bg-dots::before {
  position: absolute;
  content: "";
  background: url(../../public/images/backgrounds/testimonial-bg-1.png);
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.bg-dots .container {
  position: relative;
  z-index: 2;
}

.testimonial-slider .nextArrow,
.testimonial-slider .prevArrow {
  opacity: 0;
  transition-delay: 1s;
}

.testimonial-slider:hover .nextArrow {
  opacity: 1;
  right: -100px;
  transition-delay: 0s;
}

.testimonial-slider:hover .prevArrow {
  opacity: 1;
  left: -100px;
  transition-delay: 0s;
}

.bg-quote {
  position: relative;
}

.bg-quote::before {
  position: absolute;
  content: "\f10d";
  font-family: "FontAwesome";
  font-size: 150px;
  color: rgba(134, 195, 58, 0.3);
  top: -10px;
  line-height: 1;
  left: 50%;
  transform: translateX(-50%);
}

/* /testimonial */
/* clients logo */
.clients-logo {
  text-align: center;
  position: relative;
  height: 120px;
  width: 180px;
}

.clients-logo:hover .white {
  opacity: 0;
}

.clients-logo:hover .primary {
  opacity: 1;
}

.clients-logo .white {
  opacity: 1;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 10px;
}

.clients-logo .primary {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 10px;
}

.customer-bg-shape {
  position: absolute;
  top: 0;
  right: 50%;
}

/* /clients logo */
/* cta sm */
.cta-sm-bg {
  position: relative;
}

.cta-sm-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 90%;
  top: 0;
  background: url(../../public/images/backgrounds/cta-sm-right.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.cta-sm-bg::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url(../../public/images/backgrounds/cta-sm-left.png);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}

/* /cta sm */
/* pricing */
.pricing-table {
  transition: 0.2s ease;
  position: relative;
}

.pricing-table::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(../../public/images/backgrounds/pricing-table-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.pricing-table.active {
  background: var(--main-color);
  color: #fff;
}

.pricing-table.active h2,
.pricing-table.active h3,
.pricing-table.active h4,
.pricing-table.active h5,
.pricing-table.active h6,
.pricing-table.active .subtitle {
  color: #fff;
}

.pricing-duration {
  transition: 0.2s linear;
}

.pricing-duration.active {
  font-weight: 600;
  color: #172231;
}

.pricing-switcher {
  text-align: center;
}

.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 2px;
  border-radius: 50em;
  background: var(--main-color);
  height: 40px;
  width: 100px;
}

.pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.pricing-switcher label {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  color: #818385;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-size: 16px;
}

.pricing-switcher label.monthly {
  right: 130px;
}

.pricing-switcher label.monthly::before {
  position: absolute;
  content: "";
  height: 30px;
  width: 50px;
  border-radius: 50px;
  top: 50%;
  transform: translateY(-50%);
  right: -85px;
}

.pricing-switcher label.yearly {
  left: 130px;
}

.pricing-switcher label.yearly::before {
  position: absolute;
  content: "";
  height: 30px;
  width: 50px;
  border-radius: 50px;
  top: 50%;
  transform: translateY(-50%);
  left: -85px;
}

.pricing-switcher .switch {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 50em;
  transition: transform 0.3s;
}

.pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
  transform: translateX(60px);
}

.pricing-list li {
  position: relative;
  padding: 0 !important;
}

@media only screen and (min-width: 768px) {
  .pricing-list {
    margin: 3em 0 0;
  }

  .pricing-list:after {
    content: "";
    display: table;
    clear: both;
  }

  .pricing-list > li {
    width: 33.3333333333%;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.pricing-wrapper {
  position: relative;
}

.touch .pricing-wrapper {
  perspective: 2000px;
}

.pricing-wrapper.is-switched .is-visible {
  transform: rotateY(180deg);
  -webkit-animation: rotate 0.5s;
  animation: rotate 0.5s;
}

.pricing-wrapper.is-switched .is-hidden {
  transform: rotateY(0);
  -webkit-animation: rotate-inverse 0.5s;
  animation: rotate-inverse 0.5s;
  opacity: 0;
}

.pricing-wrapper.is-switched .is-selected {
  opacity: 1;
}

.pricing-wrapper.is-switched.reverse-animation .is-visible {
  transform: rotateY(-180deg);
  -webkit-animation: rotate-back 0.5s;
  animation: rotate-back 0.5s;
}

.pricing-wrapper.is-switched.reverse-animation .is-hidden {
  transform: rotateY(0);
  -webkit-animation: rotate-inverse-back 0.5s;
  animation: rotate-inverse-back 0.5s;
  opacity: 0;
}

.pricing-wrapper.is-switched.reverse-animation .is-selected {
  opacity: 1;
}

.pricing-wrapper > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.pricing-wrapper .is-visible {
  position: relative;
  z-index: 5;
}

.pricing-wrapper .is-hidden {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  transform: rotateY(180deg);
}

.pricing-wrapper .is-selected {
  z-index: 3 !important;
}

@-webkit-keyframes rotate {
  0% {
    transform: perspective(2000px) rotateY(0);
  }

  70% {
    transform: perspective(2000px) rotateY(200deg);
  }

  100% {
    transform: perspective(2000px) rotateY(180deg);
  }
}

@keyframes rotate {
  0% {
    transform: perspective(2000px) rotateY(0);
  }

  70% {
    transform: perspective(2000px) rotateY(200deg);
  }

  100% {
    transform: perspective(2000px) rotateY(180deg);
  }
}

@-webkit-keyframes rotate-inverse {
  0% {
    transform: perspective(2000px) rotateY(-180deg);
  }

  70% {
    transform: perspective(2000px) rotateY(20deg);
  }

  100% {
    transform: perspective(2000px) rotateY(0);
  }
}

@keyframes rotate-inverse {
  0% {
    transform: perspective(2000px) rotateY(-180deg);
  }

  70% {
    transform: perspective(2000px) rotateY(20deg);
  }

  100% {
    transform: perspective(2000px) rotateY(0);
  }
}

@-webkit-keyframes rotate-back {
  0% {
    transform: perspective(2000px) rotateY(0);
  }

  70% {
    transform: perspective(2000px) rotateY(-200deg);
  }

  100% {
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@keyframes rotate-back {
  0% {
    transform: perspective(2000px) rotateY(0);
  }

  70% {
    transform: perspective(2000px) rotateY(-200deg);
  }

  100% {
    transform: perspective(2000px) rotateY(-180deg);
  }
}

@-webkit-keyframes rotate-inverse-back {
  0% {
    transform: perspective(2000px) rotateY(180deg);
  }

  70% {
    transform: perspective(2000px) rotateY(-20deg);
  }

  100% {
    transform: perspective(2000px) rotateY(0);
  }
}

@keyframes rotate-inverse-back {
  0% {
    transform: perspective(2000px) rotateY(180deg);
  }

  70% {
    transform: perspective(2000px) rotateY(-20deg);
  }

  100% {
    transform: perspective(2000px) rotateY(0);
  }
}

/* /pricing */
/* blog */
.card-type {
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 3px 10px;
  color: #fff;
  background: var(--main-color);
  z-index: 1;
}

.card-meta {
  font-size: 14px;
}

.blog-shape-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.blog-shape-right {
  position: absolute;
  right: 0;
  top: 50%;
}

.clip-half-cycle {
  -webkit-clip-path: circle(157% at 50% -100%);
  clip-path: circle(157% at 50% -100%);
}

.title-border-left {
  position: relative;
  padding-left: 30px;
}

.title-border-left::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 30px;
  background: var(--main-color);
}

.post-search {
  border: 0;
  background: transparent;
  position: absolute;
  bottom: 15px;
  right: 0;
}

.tag-list a {
  display: inline-block;
  padding: 10px 22px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(51, 77, 128, 0.12);
  border-radius: 35px;
  margin-bottom: 15px;
  color: #818385;
}

.tag-list a:hover {
  background: var(--main-color);
  color: #fff;
  box-shadow: 0;
}

blockquote {
  padding: 30px 40px;
  font-style: italic;
  color: #172231;
  font-size: 21px;
  border-left: 2px solid var(--main-color);
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  position: relative;
  margin-bottom: 40px;
}

blockquote p {
  font-style: normal;
  margin-top: 10px;
}

/* /blog */
/* footer */
.list-styled {
  padding-left: 0;
}

.list-styled li {
  position: relative;
}

.list-styled li:hover a {
  text-decoration: underline;
}

.list-styled li.text-light::before {
  color: #ddd;
}

.list-styled.style-circle li::before {
  content: "\f10c";
}

.newsletter-form {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 35px;
}

.newsletter-form:focus {
  background: rgba(255, 255, 255, 0.1);
}

.newsletter-form::-moz-placeholder {
  color: #fff;
}

.newsletter-form:-ms-input-placeholder {
  color: #fff;
}

.newsletter-form::placeholder {
  color: #fff;
}

.btn-subscribe {
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* /footer */

.card-img-top {
  height: 280px;
  object-fit: cover;
}

.icon-120 {
  width: 120px;
}

/* Styling Accordian  */
.accordion-item {
  margin-top: 10px;
  border: none !important;
}

.accordion-button {
  padding: 20px 30px;
  border-radius: 12px !important;
  color: black !important;
  /* background-color: white !important; */
  border: 1px solid black !important;
}

.accordion-button::after {
  background-image: url(../../public/images/icons/collapse.png);
}

.accordion-button:not(.collapsed)::after {
  --bs-accordion-btn-icon-transform: rotate(360deg);
  background-image: url(../../public/images/icons/tick.png);
  transform: var(--bs-accordion-btn-icon-transform);
}

.coursemodules .accordion-button {
  /* padding: 20px 20px; */
  border-radius: 0px !important;
  color: rgb(41, 41, 41) !important;
  background-color: #fff !important;
  border: none !important;
}

.coursemodules .accordion-button::after {
  background-image: url(../../public/images/icons/collapse.png);
}

.coursemodules .accordion-button:not(.collapsed)::after {
  --bs-accordion-btn-icon-transform: rotate(360deg);
  background-image: url(../../public/images/icons/tick.png);
  transform: var(--bs-accordion-btn-icon-transform);
}

.coursemodules .accordion-button:not(.collapsed) {
  /* background: linear-gradient(73.1deg, rgba(34, 126, 34, 1) 8%, rgba(99, 162, 17, 1) 86.9%); */
  color: black !important;
  /* box-shadow: 0 0 20px #eee !important; */
}

.theatre {
  margin: 0.75rem;
  border-radius: 5px;
  box-shadow: 0 0 20px #eee;
  overflow: hidden;
}

.coursemodules .accordion-button:not(.collapsed) span {
  color: black !important;
}

.nav-pill-design .btn-primary-outline {
  color: black;
  border: 1px solid white;
  padding-inline: 20px !important;
  transition: 0.5s;
  padding: 15px;
}

.nav-pill-design .btn-primary-outline.active {
  color: rgb(248, 246, 246);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 12px rgb(235, 194, 46);
  background-color: var(--main-color);
  padding-inline: 40px !important;
}

.font-small {
  font-size: 13px;
}

.hero-section {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: grab;
  box-shadow: inset 0 0 0 2000px rgba(8, 8, 8, 0.618);
}

.swiper-slide {
  height: auto !important;
}

/* .navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
} */

.clip-cicle {
  clip-path: circle();
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.icon-calendar {
  width: 90px;
  height: 90px;
  transform: translateY(-10px);
  object-fit: contain;
}

.calendar-box {
  transition: 0.3s;
}

.calendar-box:hover {
  background: rgb(241, 240, 240);
  border: 1px solid #ef9312;
  box-shadow: 0px 0px 10px #f4b257;
}

/* .calendar-box:hover h6 {
  color: white;
} */
.pointer-navs .nav-item button {
  word-wrap: break-word;
  height: 90%;
  display: flex;
  width: fit-content;
  border-radius: 10px !important;
  align-items: center;
  margin-inline: auto;
}

.hover-pointer:hover {
  cursor: pointer;
}

.icon-pointer {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.mini-blog-image {
  width: 120px;
  height: 80px;
  object-fit: cover;
}

@media only screen and (max-width: 710px) {
  .sm-font-small {
    font-size: 38px !important;
  }
}

.icon-modal-logo {
  width: 120px;
  height: 120px;
}

.bg-center {
  background-position: center !important;
}

.text-initial {
  text-transform: none !important;
}

.breadcrumb-item {
  font-size: 15px !important;
}

ol.breadcrumb {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 750px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }

  p,
  .paragraph {
    font-size: 15px;
  }
}

ul {
  padding-left: 0;
}

li {
  color: #000;
}

.layer-bg {
  background: url(../../public/images/layer-bg.jpg) no-repeat center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.349);
}

.form-label {
  display: block;
  padding-bottom: 3px !important;
}

.bg-blue-grad {
  background: linear-gradient(#2d9ed6, #103548) !important;
}

.bg-rad-spc {
  background: rgb(65, 3, 56);
  background: radial-gradient(
    circle,
    rgba(65, 3, 56, 1) 0%,
    rgba(0, 0, 38, 1) 50%,
    rgba(1, 0, 39, 1) 100%
  );
}

b {
  font-weight: 600;
}

.fnt-small {
  font-size: 15px;
}

.bg-covered {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-inset {
  box-shadow: inset 0 0 0 2000px rgb(0, 0, 0, 0.7);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-justify {
  text-align: justify !important;
}

.nav-pill-design-2 .nav-link {
  background-color: white;
  border-color: white !important;
}

.nav-pill-design-2 .nav-link.active {
  background-color: white;
  border-color: rgb(38, 107, 227) !important;
}

.nav-pill-design-3 .nav-link {
  background-color: white;
  border-color: white !important;
}

.nav-pill-design-3 .nav-link.active {
  background-color: white;
  border-color: rgb(254, 194, 55) !important;
}

/* Course CSS Additions  */
.font-xs {
  font-size: 11px;
}

.font-sm {
  font-size: 14px;
}

.icon-50 {
  height: 50px;
  width: 50px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.icon-80 {
  height: 80px;
  width: 80px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.coursebreadcrumb {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 2000px rgba(216, 216, 216, 0.464);
  background: url(https://cdn.pixabay.com/photo/2016/02/18/19/25/pc-1207886_1280.jpg)
    no-repeat center;
  background-size: cover;
}

.grad-bg-1 {
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.grad-bg-2 {
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
}

.grad-bg-3 {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.grad-bg-4 {
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%);
}

.grad-bg-5 {
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}

.grad-bg-6 {
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
}

.grad-bg-light {
  background: rgb(255, 255, 255);
}

.certficate-container {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.certficate-container .certificate-name {
  top: 35%;
  font-size: 2.5rem;
}

.certficate-container .certificate-date {
  top: 50%;
  left: 30%;
}

.certificate-bottom {
  bottom: 150px;
  left: 10px;
  /* width: 700px !important */
}

.certificate-end-bottom {
  bottom: 10px;
  left: 10px;
  /* width: 700px !important */
}

Link {
  color: black !important;
}

.accordion-button::after {
  background-image: url(https://cdn-icons-png.flaticon.com/512/5337/5337906.png) !important;
}

.p-relative {
  position: relative;
}

@media only screen and (max-width: 992px) {
  .coursepanelColumn {
    position: absolute;
    right: -100%;
    top: 0;
    height: 100%;
    width: 350px !important;
    background-color: white;
    transition: 0.5s;
  }

  .coursepanelColumn.active {
    position: absolute;
    /* right: -100%; */
    right: 0%;
    top: 0%;
    width: 100% !important;
    background-color: white;
  }

  .coursePanelToggle {
    position: fixed;
    width: 50px !important;
    right: 0px;
    top: 45%;
    border-radius: 0px 0px 0px 0px !important;
  }
}

.module-sublist li a {
  color: black;
}

.module-sublist li {
  color: black;
  position: relative;
  background: white;
}

.module-sublist li.active {
  color: grey;
  position: relative;
  background-image: linear-gradient(
    to right,
    #f09819 0%,
    #edde5d 51%,
    #f09819 100%
  );
  font-weight: 600;
  transition: 0.3s;
  background-size: 200% auto;
  /* color: white; */
  border-radius: 10px;
}

/* user agent stylesheet */
::marker {
  display: none !important;
}

.bg-grad {
  background-image: linear-gradient(
    to right,
    #f09819 0%,
    #edde5d 51%,
    #f09819 100%
  );
}

.center-mp-name {
  bottom: 16%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  text-align: center;
}

.center-mp-name p {
  font-size: 14px;
  margin: 0;
}

.center-student-name {
  top: 33%;
  width: 100%;
  font-size: 55px;
  text-transform: capitalize !important;
  overflow: visible;
  text-align: center;
}

.bg-asteriod-gray {
  background: #0f2027;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2c5364, #203a43, #0f2027);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2c5364, #203a43, #0f2027);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn-grad-pills {
  color: #16222a;
}

.btn-grad-pills.active {
  background-image: linear-gradient(
    to right,
    #16222a 0%,
    #3a6073 51%,
    #16222a 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.active.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.special-nav-row .col .dropdown-item {
  border: 1px solid rgb(209, 209, 209);
  padding: 8px 8px;
  border-radius: 8px;
  margin: 4px;
  word-wrap: break-word !important;
}

.special-nav-row .col .dropdown-item:hover,
.special-nav-row .col .dropdown-item.active {
  background-color: var(--main-color) !important;
  color: white !important;
  border-color: var(--main-color);
}

.special-nav-row .col .dropdown-item .design,
.special-nav-row .col .dropdown-item .design {
  background-color: var(--main-color) !important;
  color: white !important;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.special-nav-row .col .dropdown-item:hover .design,
.special-nav-row .col .dropdown-item.active .design {
  background-color: white !important;
  color: var(--main-color) !important;
}

.cmVid {
  min-height: 250px;
}

.bg-grad-white {
  background-image: radial-gradient(
    circle 1224px at 10.6% 8.8%,
    rgba(255, 255, 255, 1) 0%,
    rgb(190, 190, 190) 100.2%
  );
}

.react-pdf__Page__canvas {
  width: 100% !important;
  object-fit: contain !important;
  height: 500px !important;
  overflow-y: auto;
}

.fullscreen .react-pdf__Page__canvas {
  width: 100% !important;
  object-fit: contain !important;
  height: 93vh !important;
}

.html5-endscreen.ytp-player-content.videowall-endscreen.ytp-show-tiles {
  display: none !important;
}

.nav-pills-design-4 {
  padding-left: 0;
}

.nav-pills-design-4 {
  display: flex;
  overflow: auto;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  padding-bottom: 6px;
}

.nav-pills-design-4 .nav-item .nav-link {
  display: flex;
}

@media only screen and (max-width: 700px) {
  .nav-pills-design-4 .nav-item .nav-link {
    font-size: small !important;
  }

  .nav-pills-design-4 .nav-item .nav-link svg {
    height: 15px !important;
  }
}

@media only screen and (max-width: 900px) {
  .nav-pills-button {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
    scroll-behavior: smooth;
    margin-left: 25px;
  }
}

.mobile .nav-item .nav-link {
  margin: 0;
  padding: 10px 20px !important;
  border-radius: 0 !important;
  background-color: white;
  border-top: 3px solid rgb(255, 255, 255);
  color: black;
  border-bottom: none !important;
}

.mobile .nav-item .nav-link.active {
  margin: 0;
  background-color: rgb(255, 246, 167);
  color: rgb(241, 113, 9);
  border-top: 3px solid rgb(241, 113, 9) !important;
  border-bottom: none !important;
}

.nav-pills-design-4 .nav-item .nav-link {
  margin: 0;
  padding: 10px 20px !important;
  border-radius: 0 !important;
  background-color: white;
  border-bottom: 3px solid rgb(255, 255, 255);
  color: black;
}

.nav-pills-design-4 .nav-item .nav-link.active {
  margin: 0;
  background-color: rgb(255, 246, 167);
  color: rgb(241, 113, 9);
  border-bottom: 3px solid rgb(241, 113, 9);
}

.nav-pills-design-5 {
  padding-left: 0;
}

.nav-pills-design-5 .nav-item .nav-link {
  margin: 3px;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.nav-pills-design-5 .nav-item .nav-link.active {
  margin: 3px;
  border-radius: 10px !important;
  background-color: var(--main-color);
  color: white;
  box-shadow: 2px 2px 2px rgb(229, 229, 229);
}

.yellow-bg-grad {
  background-image: linear-gradient(
    135deg,
    var(--main-color) 10%,
    var(--main-bg-color) 100%
  );
}

.color-initial {
  color: initial;
}

.hover-bg-primary {
  transition: 0.5s;
}

.hover-bg-primary:hover {
  background-color: var(--main-bg-color);
}

.hover-bg-primary:hover span {
  color: white !important;
}

.hover-bg-primary:hover .design {
  background-color: white !important;
  color: var(--main-color) !important;
}

.sidebar .list-group .list-group-item {
  background-color: rgb(227, 235, 237);
}

.sidebar .list-group .list-group-item.active {
  background: linear-gradient(to left, #2d9ed6, #103548);
}

.designed-table tr:hover {
  background-color: whitesmoke;
}

.designed-table tr th,
.designed-table tr td {
  font-size: 14px;
}

.carousel .show-hover {
  visibility: hidden;
  transition: 0.5s;
}

.carousel:hover .show-hover {
  visibility: visible;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-blog {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bg-green-grad {
  background: linear-gradient(
    73.1deg,
    rgba(34, 126, 34, 1) 8%,
    rgba(99, 162, 17, 1) 86.9%
  );
  color: white !important;
  box-shadow: 0 0 20px #eee !important;
}

.bg-grey {
  background-color: rgb(227, 227, 227);
}

.MuiStepConnector-line {
  display: none !important;
}

.bg-light-green-grad {
  background-image: linear-gradient(
    136deg,
    #81fbb8 0%,
    #28c76f 50%,
    #28c76f 100%
  );
}

.bg-light-maroon-grad {
  background-image: linear-gradient(135deg, #fad7a1 10%, #e96d71 100%);
}

.bg-light-violet-grad {
  background-image: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
}

.btn-bg-green-grad {
  background-image: linear-gradient(
    136deg,
    #81fbb8 0%,
    #28c76f 50%,
    #28c76f 100%
  );
  transition: 0.3s;
}

.btn-bg-green-grad:hover {
  background-image: linear-gradient(
    136deg,
    #31e080 0%,
    #09b557 50%,
    #06b455 100%
  );
}

.btn-bg-green-grad.hover {
  background-image: linear-gradient(
    136deg,
    #31e080 0%,
    #09b557 50%,
    #06b455 100%
  );
}

.swal2-popup {
  border-radius: 18px !important;
}

.swal2-title {
  padding-top: 10 !important;
}

.swal2-image {
  margin: 2.5em auto 0em !important;
}

.swal2-styled {
  border-radius: 10px !important;
  padding: 10px 30px !important;
  margin-bottom: 2em !important;
}

.css-169iwlq-MuiCalendarPicker-root {
  margin-inline: auto !important;
}

.new-design-table td,
.new-design-table th {
  padding: 15px;
}

.rounded .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.rounded .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

.widget4,
.vedio-container {
  height: 220px !important;
}

@media only screen and (min-width: 428px) {
  .widget4,
  .vedio-container {
    height: 240px !important;
  }
}

@media only screen and (min-width: 500px) {
  .widget4,
  .vedio-container {
    height: 280px !important;
  }
}

@media only screen and (min-width: 600px) {
  .widget4,
  .vedio-container {
    height: 340px !important;
  }
}

@media only screen and (min-width: 700px) {
  .widget4,
  .vedio-container {
    height: 420px !important;
  }
}

@media only screen and (min-width: 800px) {
  .widget4,
  .vedio-container {
    height: 460px !important;
  }
}

@media only screen and (min-width: 900px) {
  .widget4,
  .vedio-container {
    height: 510px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .widget4,
  .vedio-container {
    height: 420px !important;
  }
}

@media only screen and (min-width: 1100px) {
  .widget4,
  .vedio-container {
    height: 465px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .widget4,
  .vedio-container {
    height: 510px !important;
  }
}

@media only screen and (min-width: 1300px) {
  .widget4,
  .vedio-container {
    height: 550px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .widget4,
  .vedio-container {
    height: 590px !important;
  }
}

@media only screen and (min-width: 1500px) {
  .widget4,
  .vedio-container {
    height: 630px !important;
  }
}

@media only screen and (min-width: 1500px) {
  .widget4,
  .vedio-container {
    height: 650px !important;
  }
}

@media only screen and (max-width: 700px) {
  .mobile-simple-tabs .MuiButton-contained .button-span {
    display: block;
  }

  .mobile-simple-tabs .MuiButton-outlined .button-span {
    display: none;
  }
}

.eventsbg {
  background-image: url("https://demo.xpeedstudio.com/evenex/multi-event/wp-content/uploads/sites/13/2021/02/feature-event-background-image.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: multiply;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.bg-light-white2-grad {
  background: rgb(219, 218, 236);
  background: linear-gradient(
    90deg,
    rgba(219, 218, 236, 1) 0%,
    rgba(189, 206, 210, 1) 100%
  );
}

.MuiCalendarOrClockPicker-root.css-j3g793-MuiCalendarOrClockPicker-root
  .MuiPickersToolbar-penIconButtonLandscape.css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
  display: none;
}

.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
  display: none;
}

.galleryswiper .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.galleryswiper .swiper-pagination .swiper-pagination-bullet {
  transition: 0.3s;
  border-radius: 8px;
  background-image: linear-gradient(#2d9ed6, #103548);
  padding: 6px;
}

.galleryswiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 100px !important;
  border-radius: 0%;
  border-radius: 8px;
}

.onboard-step-3 .MuiDialogActions-root {
  display: none !important;
}

.onboard-step-3 .MuiIconButton-sizeMedium {
  display: none !important;
}

.MuiPickerStaticWrapper-root .MuiDialogActions-root,
.MuiPickerStaticWrapper-root .MuiIconButton-sizeMedium {
  display: none !important;
}

.youtube-iframe {
  min-height: 230px;
}

.youtube-iframe body,
.youtube-iframe #movie_player {
  background: transparent !important;
}

@media only screen and (min-width: 450px) {
  .youtube-iframe {
    min-height: 280px;
  }
}

@media only screen and (min-width: 650px) {
  .youtube-iframe {
    min-height: 380px;
  }
}

@media only screen and (min-width: 1400px) {
  .youtube-iframe {
    min-height: 430px;
  }
}

@media only screen and (min-width: 1700px) {
  .youtube-iframe {
    min-height: 480px;
  }
}

.certified-wrapper .badge-promo {
  position: relative;
  overflow: hidden;
  max-height: 60px;
  margin-bottom: -8px;
  z-index: 1;
  padding: 12px 14px;
  border-radius: 2px 2px 0 0;
  color: #fff;
  text-align: left;
}

.certified-wrapper .badge-promo:before,
.certified-wrapper .badge-promo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}

.certified-wrapper .badge-promo:before {
  background: rgba(255, 183, 0, 0.642);
  height: 100%;
  width: 110%;
  transform-origin: bottom right;
  transform: rotate(1deg);
}

.certified-wrapper .badge-promo:after {
  background: var(--main-color);
  transform: rotate(-3deg);
  transform-origin: bottom left;
  height: 100%;
  width: 110%;
}

.certified-wrapper .badge-promo-content {
  position: relative;
  z-index: 1;
  font-size: 18px;
}

.hover-primary {
  border: 2px dashed lightgray !important;
}

.hover-primary:hover {
  border: 2px solid var(--main-color) !important;
  background-color: white !important;
  box-shadow: -8px 8px lightblue;
}

.captcha-simple div div {
  display: flex;
}

.captcha-simple #reload_href {
  padding: 5px;
  border: 1px solid green;
  font-size: 10px;
  color: green !important;
  margin-bottom: 5px;
}

.captcha-simple #user_captcha_input {
  padding: 10px;
  width: 100% !important;
  margin-top: 3px !important;
  border: 1px solid black;
}

.scroll-minibar::-webkit-scrollbar {
  width: 2px;
  scroll-margin-right: 2px;
  scroll-behavior: smooth;
  overflow: hidden;
  /* background-color: blueviolet; */
}

.scroll-minibar::-webkit-scrollbar-thumb {
  background-color: gray !important;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.img-contain {
  object-fit: contain;
}

.supporters {
  background: url("https:www.glcloud.in/images/static/graphics/world-map.svg")
    center !important;
  background-size: cover;
  background-blend-mode: multiply;
}

.supporters a img {
  background-color: white;
  transition: 0.5s;
}

.supporters a:hover img {
  border-color: var(--main-color) !important;
  box-shadow: 4px 4px var(--main-color) !important;
}

.insta-feeds .swiper-slide-active .card-social {
  background-color: var(--main-bg-color) !important;
  scale: 1.05;
  transition: 0.5s;
}

.album-card .card-body {
  opacity: 0;
  transition: 0.5s;
  text-align: center;
  z-index: 10;
  overflow: visible;
}

.album-card:hover .card-body {
  opacity: 1;
  border: 3px solid var(--main-color) !important;
  box-shadow: 5px 5px var(--main-color);
  z-index: 200;
}

.certificate-card {
  border: 1px solid grey;
  padding: 8px;
}

.certificate-card img {
  transition: 0.3s;
}

.certificate-card:hover img {
  filter: blur(1px);
}

.certificate-card .link-container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.certificate-card .link-container .link {
  padding: 25px;
  clip-path: circle();
  background-color: rgba(255, 255, 255, 0.896);
  font-size: 22px;
  font-weight: 500;
  transition: 0.5s;
}

/* Notification Menu Tabs  */
.notificationTab {
  border: none;
  padding: 0px 15px;
}

.notificationTab .nav-item .nav-link {
  border: 1px solid grey;
  border-radius: 50px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 12px;
  color: #000;
}

.notificationTab .nav-item .nav-link.active {
  border: 1px solid grey;
  border-radius: 50px;
  background-color: #01d95a;
  border-color: #01d95a;
  color: white;
}

.notificationTab .nav-item .nav-link.active::before {
  content: "";
}

.notification-item .headline {
  font-size: 13px !important;
  font-weight: 400;
}

.notification-item .subline {
  font-size: 13px !important;
}

.notification-item .date {
  font-size: 10px !important;
}

/* Setting Navs  */
.settings-navs {
  border-bottom: none;
}

.settings-navs a,
.settings-navs .nav-link {
  border-bottom: 2px solid rgba(128, 128, 128, 0.361);
  padding: 8px 12px;
  border-radius: 0px;
  margin-bottom: 0px;
  font-weight: 500;
  margin-right: 0px;
  transition: 0.3s;
  color: #000000ea;
}

.settings-navs a.active,
.settings-navs .nav-link.active {
  border-bottom: 2px solid var(--main-color);
  color: var(--main-color);
  font-weight: 500;
  /* box-shadow: 0px 2px 5px lightgreen; */
  /* background-color: #01d95a; */
}

.cursor-pointer {
  cursor: pointer;
}

.color-blue {
  color: #0093e9;
}

/* Side pannel css */
.sideMenuItem {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 7px !important;
  margin-left: 20px !important;
  margin-right: 15px !important;
  padding-bottom: 7px !important;
  color: #000000;
  border-radius: 8px !important;
  font-size: 5px !important;
  transition: all 0.3s;
}

.sideMenuItem:hover {
  background: #c7d4ff !important;
  transition: all 0.3s;
}

/* .sideMenuItem:hover div span{
  color:white !important
} */

/* Side pannel scroll bar style */
.sidepannelScroll {
  scroll-behavior: smooth;
  overflow-x: auto;
}

.sidepannelScroll::-webkit-scrollbar {
  width: 6px;
}

.sidepannelScroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cac5fd;
}

.ssidepannelScroll::-webkit-scrollbar-track {
  background-color: #f2eeee;
}

/* =====================redemptionStoreCss======================= */

.my_container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

header {
  min-height: 100vh;
}

.siderBar {
  width: 20%;
  height: 100%;
  box-shadow: 0 20px 60px #ebf2f480;
}

.main {
  width: 80%;
}

.sidebar-items {
  width: 100%;
  height: 760px;
}

.sidebar-items a span {
  color: #7000ff;
}

/* =====checkoutcss====
 */
.items div p:first-child {
  width: 70%;
}

.firstitem {
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  padding-top: 1rem;
}

.thirditem {
  padding-top: 1rem;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
}

.items div p:nth-child(2) {
  width: 20%;
}

.items div p:nth-child(3) {
  width: 10%;
}

.items div:nth-child(2) div:nth-child(1) {
  width: 70%;
}

.items div:nth-child(2) div:nth-child(2) {
  width: 20%;
}

.items div:nth-child(2) div:nth-child(3) {
  width: 10%;
}

.secitemimg {
  width: 10% !important;
}

.secitemcontent {
  width: 80% !important;
}

.secitemcontent p:nth-child(1) {
  font-size: 12px;
  font-weight: 700;
}

.secitemcontent p:nth-child(2) {
  font-size: 21px;
  color: #f0ad00;
  font-weight: 700;
}

.seconditeminput input {
  background-color: #f4f4f4;
  border: none;
  width: 50%;
}

.contents > p:nth-child(1) {
  color: #5c5c5c;
  font-size: 19px;
  font-weight: 600;
}

.contents > p:nth-child(2) {
  font-size: 16px;
  font-weight: 700;
}

.contents > p:nth-child(3) {
  color: #5c5c5c;
  font-size: 19px;
  font-weight: 600;
}

.contents > p:nth-child(3) > span {
  color: #ad00ff;
  font-size: 17px;
  font-weight: 600;
}

.lastfieldCheckout button {
  background-color: #7000ff;
  color: white;
  border-radius: 11px;
  border: none;
  padding: 0.5rem 3rem;
}

/* ===Storemain filed css=== */

.Redemption p {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Redemption p span {
  color: #d1a300;
  font-size: 31px;
}

.creadit {
  padding-top: 0.5rem;
}

.main {
  width: 100%;
}

.mainContent div {
  box-shadow: 0 0 20px #d7e0e973;
  text-align: center;
  border-radius: 27px;
  height: 404px;
  padding: 1rem;
  flex-basis: 200px;
}

.mainContent div p {
  font-weight: 600;
  font-size: 14px;
}

.mainContent div h4 {
  font-weight: 600;
  font-size: 21px;
  color: #34004d;
}

.mainContent div button {
  background-color: #7000ff;
  color: white;
  border-radius: 11px;
  border: none;
  padding: 0.5rem 3rem;
}

/* ============receiptComponentsCss============ */
.middleItems > p:first-child {
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #6d6d6d;
  margin: 0;
}

.middleItems > p:last-child {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #6d6d6d;
  margin: 0;
}

.receiptContent div:nth-child(1) > p:nth-child(1) {
  color: #5c5c5c;
  font-size: 19px;
  font-weight: 600;
}

.receiptContent div:nth-child(1) > p:nth-child(2) {
  font-size: 16px;
  font-weight: 700;
}

.receiptContent div:nth-child(1) > p:nth-child(3) {
  color: #5c5c5c;
  font-size: 19px;
  font-weight: 600;
}

.tracingOrderbtn {
  color: #7000ff;
  font-weight: 700;
}

/* ================yourOrderComponentDesign=============== */
.orderitems {
  background: #ddbdf5;
  border-radius: 24px;
}

.orderitems div {
  color: #4e007e;
}

.seconitemsorders,
.seconitemsorders a {
  color: #3e006f;
  font-weight: 700;
}

.mainyourorder {
  background-color: #fafafa;
  border-radius: 24px;
}

.feedbackbtn {
  border-radius: 11px;
  background: #7000ff;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
}

.redeembtn {
  color: #8000ff;
}

/* ===================orderDetailsComponetDesing=============== */

.orderdetails {
  background: #f6f6f6;
  border-radius: 27px;
}

.google-verify-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.google-verify-loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ============  onboard dublicate institute checker css  ================= */

.collegeItems p:nth-child(1) {
  font-size: 19px;
  font-weight: 600;
  color: #2d008d;
}

.collegeItems p:nth-child(1) span {
  font-size: 15px;
  font-weight: 400;
  color: #6100ff;
}

.popnone {
  overflow: auto;
  opacity: 0;
}

.detailbtn {
  color: #6100ff;
  /* font-size: 15px; */
  font-weight: 600;
}

.collegeparaFirst {
  color: #636363;
  font-weight: 500;
  font-size: 15px;
}

.collegeItemsSpan {
  color: #7c7c7c;
  font-size: 15px;
}

.popedelement {
  position: absolute;
  width: 85%;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
  z-index: 5;
  padding: 3rem;
  border-radius: 36px;
  box-shadow: 0 0 5px 4px #c1bcbc;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%);
  transition: all 0.2s;
}

.inneritems {
  background: #fffdd9;
  border-radius: 23px;
  padding: 0.5rem 5rem;
}

.inneritems p:nth-child(1) {
  font-weight: 700;
  color: #675d00;
  font-size: 19px;
}

.inneritems p:nth-child(2) {
  font-size: 19px;
  font-weight: 400;
}

.matchFoundPara {
  font-size: 19px;
  font-weight: 600;
}

.nonepara {
  color: #ab00fb;
  font-size: 19px;
  font-weight: 600;
}

.colleges {
  width: 95%;
  margin-left: 10px;
  border: 3px solid #4200ff;
  border-radius: 21px;
  margin-bottom: 1rem !important;
}

/* ==============duplicateAccountCss=========== */

.dupliPara {
  color: #675d00;
  font-size: 19px;
  font-weight: 700;
}

.dupliParaTwo {
  font-size: 19px;
  font-weight: 500;
}

.firstFiled,
.secondField {
  background-color: #fafafa;
  padding: 1rem;
  margin: 0.1rem 0;
  border-radius: 15px;
}

.processbtn {
  background-color: #0008c8;
  padding: 0.5rem 3rem;
  border-radius: 10px;
  font-weight: 500;
}

/* ==============previous Account========== */

.previousAccountThank {
  border: 1px solid #edcfff;
  background: #f0ebff;
  border-radius: 13px;
}

@media (max-width: 770px) {
  .approve-inner-css {
    margin-top: 10px;
  }
}

.approve-request {
  font-size: 32px;
  font-weight: 600;
}

.my-teacher-student-content {
  font-size: 32px;
  font-weight: 600;
}

@media (max-width: 551px) {
  .approve-reject-box {
    margin: auto;
  }

  .approve-request {
    font-size: 25px;
  }

  .my-teacher-student-content {
    font-size: 25px;
  }
}

@media (max-width: 570px) {
  .student-teacher-serach {
    margin-top: 8px;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }
}

.sign-in-title {
  font-size: 35px;
  font-weight: 600;
}

.credentials-content {
  font-size: 19px;
  font-weight: 500;
}

.sign-in-google {
  font-size: 19px;
}

.create-acc-title {
  font-size: 32px;
}

.enter-password-content {
  font-size: 24px;
}

.intro-content-crate-acc {
  font-size: 18px;
}

.yuva-logo-img {
  /* height: 30px; */
  width: 10rem;
}

.choose-acc-type {
  font-size: 35px;
}

.font-stud-teach-insti {
  font-size: 22px;
}

.register-detail-content {
  font-size: 19px;
}

.email-verified {
  font-size: 22px;
}

.new-confirm-password {
  font-size: 19px;
}

.signup-content-password {
  font-size: 17px;
}

.create-institute-title {
  font-size: 35px;
}

.verify-content {
  font-size: 18px;
}

.yuvamanthan_logo1 {
  visibility: hidden;
}

@media (max-width: 426px) {
  .sign-in-title {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
  }

  .credentials-content {
    font-size: 17px;
    font-weight: 500;
  }

  .guest-content {
    font-size: 16px;
    font-weight: 500;
  }

  .sign-in-google {
    font-size: 16px;
  }

  .enter-password-content {
    font-size: 22px;
  }

  .create-acc-title {
    font-size: 28px;
  }

  .intro-content-crate-acc {
    font-size: 16px;
  }

  .yuva-logo-img {
    height: 30px;
    width: 12rem;
  }

  .choose-acc-type {
    font-size: 22px;
  }

  .font-stud-teach-insti {
    font-size: 18px;
  }

  .register-detail-content {
    font-size: 17px;
  }

  .signup-content {
    font-size: 14px;
  }

  .signup-content-password {
    font-size: 14px;
  }

  .email-verified {
    font-size: 18px;
  }

  .verified-icon {
    width: 9%;
  }

  .new-confirm-password {
    font-size: 18px;
  }

  .enter-password-in-div {
    text-align: center;
  }

  .learn-more-span-div {
    margin-top: 30px;
    font-size: 14px;
  }

  /* .learn-more-span{
    margin-top: 10px;
  } */

  .create-institute-title {
    font-size: 22px;
  }

  .verify-content {
    font-size: 16px;
  }
}

.having-trouble {
  font-size: 15px;
}

@media (max-width: 426px) {
  .having-trouble {
    font-size: 16px;
  }
}

.yui-card {
  background: white !important;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.79);
  border-radius: 18px !important;
  padding: 15px !important;
}

.yui-card-new {
  background: white !important;
  box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.79);
  border-radius: 18px !important;
}

/* Styling links within the footer */
.footer a {
  color: #ffffff;
  /* Orange color for links */
  text-decoration: none;
  /* Remove underlines from links */
  font-size: medium;
}

/* Hover effect for links */
.footer a:hover {
  color: rgb(245, 245, 173);
  text-decoration: underline;
  /* Underline on hover */
}

.footer ul li {
  margin-bottom: 5px;
}

.themes {
  font-family: "Poppins" !important;
}

.themes h3 {
  font-size: 30px !important;
  font-weight: 600 !important;
  color: rgb(119, 0, 216) !important;
}

.themes h4 {
  font-size: 22px !important;
  font-weight: 600 !important;
  color: rgb(49, 0, 88) !important;
}

.themes p {
  text-align: justify;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.themes ul li span {
  font-weight: 300 !important;
  font-size: 16px !important;
}

.themes ul {
  list-style-type: circle !important;
}

.btn-outline-type1 {
  border: 1px solid #7e22ce !important;
  color: var(--primary);
  transition: 0.6s;
}

.btn-outline-type1:hover {
  background: #da9eff !important;
  transform: scale(1.02);
  color: #ffffff !important;
}

.Endorsed-Yuva-landing-page {
  /* background:rgb(204, 204, 255); */
}

.Endorsed-Yuva-landing-page:hover {
  opacity: 0.9;
  background: rgb(230, 230, 255);
}

@keyframes bounceLatestChat {
  0% {
  }

  50% {
    transform: translateY(7px);
  }

  60% {
    transform: translateY(7px);
  }
}

.bounceLatestChat {
  animation: bounceLatestChat 1s infinite;
}

@media (max-width: 767px) {
  .w-sm-50 {
    width: 80% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

.thin-scroll::-webkit-scrollbar {
  width: 2px;
  /* Adjust the width of the scrollbar */
}

.thin-scroll::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color of the track */
}

.thin-scroll::-webkit-scrollbar-thumb {
  background: #888;
  /* Set the color of the thumb */
}

.zero-scroll::-webkit-scrollbar {
  width: 0px;
  /* Adjust the width of the scrollbar */
}

.zero-scroll::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color of the track */
}

.zero-scroll::-webkit-scrollbar-thumb {
  background: #888;
  /* Set the color of the thumb */
}

@media (max-width: 600px) {
  .gallerySlider {
    width: 350px !important;
    margin: auto;
  }
}

.logos {
  width: 100%;
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
}

.logos::before,
.logos::after {
  position: relative;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos::before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos::after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos-slide {
  display: flex;
  animation: 40s slide infinite linear;
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide img {
  height: 150px;
  margin: 0 40px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.button-emoji {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.button:hover .button-emoji {
  display: block;
}

@keyframes bounceUndp {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounce-animation-undp {
  animation: bounce 1s;
  animation-fill-mode: forwards;
}

.caro-inner-bg {
  background-image: url("/public/secbanner/1.png");
}

/* ==========================logo slider css===================== */

/* @keyframes white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
} */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-180px * 8));
  }
}

.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 170px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;

  &::before,
  &::after {
    content: "";
    height: 170px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  /* .slide-track {
		animation: scroll 40s linear infinite;
		display: flex;
		width: calc(180px * 14);
    overflow: hidden;
    border: 3px solid blue;
	} */

  /* .slide {
		height: 170px;
		width: 180px;
    padding: 0.5rem;
	} */
  /* .slide img{
    height: 100%;
  } */
}

.clubdetails {
  background-image: linear-gradient(
    90deg,
    rgba(192, 192, 192, 0.185),
    rgba(192, 192, 192, 0.185)
  );
}

.club-right-banner {
  border-radius: 21% 0% 0% 100% / 62% 0% 0% 100%;
  height: 300px;
  box-shadow: 0 0 10px silver;
  object-fit: cover;
  object-position: center;
}

.infaSlider {
  height: 200px;
  margin: auto;
}

.infaSliderTwo {
  height: 200px;
  background-color: white;
  margin-bottom: 0.5rem;
}

/* =====================media post desing================= */

.postDots span {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: black;
}

.blurry-overlay-events {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(20px);
  /* Adjust the blur radius as needed */
  z-index: 1;
  /* Ensure the overlay is behind the image */
  pointer-events: none;
  /* Allows clicks to go through the overlay */
}

.blink_reminder {
  animation: blinker 1.2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.css-1gw9vc6-JoyInput-input {
  width: 100% !important;
}

@media (min-width: 577px) {
  .smallScreenLandigPageLayout {
    display: flex;
  }
}

.bg-darkprime {
  background-color: #194866 !important;
}

.state_container {
  visibility: hidden;
}

.state_container_dekstop {
  visibility: visible;
}

.state-item {
  display: flex;
  flex-direction: column;
}

.radio-input {
  width: 26px;
  height: 26px;
  margin-top: 20px;
}

.state-label {
  width: 235px;
  height: 36px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 24px;
  color: #27445d;
}

.divider {
  width: 440px;
  border: 1px solid #d9d9d9;
  margin-top: 10px;
}

.schoollist_container {
  visibility: hidden;
}

.statelist_container_dekstop {
  visibility: visible;
}

.main_container{
  position:relative;
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Media Query for Small Screens (e.g., screens less than 640px) */
@media (max-width: 640px) {
  .state_container {
    visibility: visible;
    height: 100%;
  }

  .state_container_dekstop {
    visibility: hidden;
  }

  .statelist_container_dekstop {
    visibility: hidden;
  }

  .state-item {
    flex-direction: row;
    align-items: center;
  }

  .radio-input {
    margin-top: 0;
    margin-left: 0;
  }

  .state-label {
    width: auto;
    padding-left: 15px;
    margin-top: 0;
    font-size: 20px;
  }

  .state {
    margin-left: 10px;
  }

  .divider {
    width: 100%;
  }

  .schoolname {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-style: semibold;
  }

  .heading {
    background-color: rgba(39, 68, 93, 1);
    display: flex;
    justify-content: center;
    width: 324;
    height: 27;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0%;
    padding-left: 120px;
    padding-top: -15px;
    color: rgba(255, 255, 255, 1);
  }

  .data_heading {
    width: 204;
    height: 33;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0%;
    color: #030717;
    margin-left: 40px;
    margin-top: -34px;
  }

  body {
    margin: 0px;
  }

  .App {
    margin: 0px;
    overflow-x: hidden;
    text-decoration: none;
    width: 100vw;
    height: 100%;
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    text-decoration: none;
    overflow-x: hidden;
    height: 100%;
    font-family: "Poppins", sans-serif;
  }

  h1 {
    font-family: "Poppins", sans-serif;
  }
  .state {
    font-size: 24px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-style: semibold;
  }

  .btn {
    border-radius: 12px;
    margin-bottom: 20px;
  }

  .searchbar {
    background-color: rgb(246, 246, 246);
    border: none;
    font-size: 18px;
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.95);
    padding-left: 10px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }

  .optn {
    border-radius: 10px;
    margin-left: 50px;
    font-size: 20px;
    font-style: bold;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    padding-left: 10px;
    padding-right: 10px;
  }

  .schoollist_container {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    visibility: visible;
  }

  .schoolname {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-style: semibold;
  }

  .container {
    height: 90px;
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
  }

  .schoolname1 {
    font-size: 20px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    line-height: 20px;
    padding-top: 25px;
    padding-left: 20px;
    text-decoration: none;
  }

  td {
    text-decoration: none;
  }
  .dist {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    line-height: 20px;
    padding-left: 20px;
    text-decoration: none;
  }

  .pending {
    color: rgba(255, 17, 8, 1);
  }

  .complete {
    color: rgba(31, 177, 65, 1);
  }

  .box {
    scroll-behavior: smooth;
    margin-bottom: 20px;
  }

  .footer {
    border: 1px solid rgba(217, 217, 217, 1);
    margin-bottom: 250px;
    padding-left: 15px;
    margin-right: -20px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
  }

  .p1 {
    background: rgba(39, 68, 93, 1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    color: rgba(255, 255, 255, 1);
    margin-left: 7px;
  }

  .p-all {
    width: 12;
    height: 30;
    top: 877px;
    margin-left: 8px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    color: rgba(39, 68, 93, 1);
  }

  .arrow {
    font-size: 30px;
    width: 40px;
    margin-top: 2px;
  }

  .dot {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
  }

  .heading {
    background-color: rgba(39, 68, 93, 1);
    width: 724;
    height: 44px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0%;
    padding-left: 22px;
    padding-top: 7px;
    color: rgba(255, 255, 255, 1);
  }

  .icon {
    font-size: 26px;
    margin-left: 20px;
  }

  .data_heading {
    /* width: 204px;
    height: 33px; */
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0%;
    color: #030717;
    margin-left: 60px;
  }

  .data_container {
    margin-top: 20px;
  }

  .data_content {
    width: 78;
    height: 20;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    color: #6b46c1;
  }

  .list {
    font-family: "Poppins", sans-serif;
  }

  .option {
    width: 144;
    height: 27;
    top: 941px;
    left: 78px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0%;
    color: #6b46c1;
  }

  .addicon {
    height: 50px;
    width: 50px;
    font-size: 40px;
    color: red;
    margin-left: 50px;
    margin-top: 15px;
  }

  .arrow1 {
    color: #030717;
    font-size: 28px;
  }
  .select {
    width: 249;
    height: 54;
    top: 1164px;
    left: 64px;
    border-radius: 6px;
    border-width: 2px;
    border: 2px solid #6b46c1;
    padding: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #6b46c1;
    letter-spacing: 0%;
  }

  .schooldetails {
    width: 100%;
    overflow-x: hidden;
  }

  .btn {
    margin-left: 100px;
    padding-left: -2px;
    background-color: #27445d;
    color: white;
    margin-top: 50px;
  }

  .remarks {
    width: 297px;
    height: 54px;
    top: 1282px;
    margin-left: 24px;
    border-radius: 6px;
    color: #030717b2;
    border-width: 2px;
    border: 2px solid #6b46c1;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0%;
    padding-left: 18px;
    margin-top: -20px;
  }

  .sent_box {
    width: 54px;
    height: 54px;
    top: 1284px;
    left: 374px;
    background: #6b46c1;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .sent {
    color: white;
    width: 30px;
    height: 30px;
    top: 1294px;
    left: 387px;
  }

  .comment {
    margin-left: 20px;
    border: none;
    margin-top: 20px;
    width: 310px;
    height: 219px;
    top: 1359px;
    left: 27px;
    border-radius: 8px;
    background: #d9d9d933;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0%;
    color: #030717;
    padding: 20px;
    margin-bottom: 330px;
  }

  .comment textarea {
    color: #030717;
  }
}

.slide-right {
  transform: translateX(100%);
}

.slide-left {
  transform: translateX(-100%);
}

.para {
  font-size: 20px;
}

.leaf {
  transform: scale(0.5);
}

.cloud1 {
  visibility: hidden;
}

.cloud2 {
  visibility: visible;
}

@media (max-width: 768px) {
  .cloud2 {
    visibility: hidden;
  }
  .box {
    padding: 10px;
  }
  .box h1 {
    font-size: 24px;
  }
  .box p {
    font-size: 16px;
  }

  .yuvamanthan_logo {
    margin-left: auto;
  }

  .yuvamanthan_logo1 {
    visibility: visible;
  }

  .cloud {
    margin-top: -600px;
  }

  .cloud1 {
    visibility: visible;
  }

  .paragraph_para {
    font-size: 16px;
  }

  .reco {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .subheading {
    margin-left: 15px;
    margin-top: 30px;
  }

  .leaf {
    /* visibility: hidden; */
    height: 15px;
    width: 15px;
    margin-top: -10px;
    /* transform: scale(0.4); */
    /* margin-left: -30px;
    padding-left: -50px;
    margin-right: 20px;
    padding-top: -20px; */
  }

  .leaf_1 {
    margin-left: -80px;
  }

  .leaf_2 {
    /* visibility: hidden; */
    height: 15px;
    width: 15px;
    margin-top: -10px;
    /* transform: scale(0.4); */
    margin-left: -30px;
    padding-left: -50px;
    padding-top: -20px;
  }

  .box {
    display: flex;
    flex-direction: column;
  }

  .leader_img {
    margin-left: -20px;
  }

  .h {
    text-align: center;
    width: 400px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    margin-left: 10px;
  }

  .h_1 {
    text-align: center;
    width: 400px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    margin-left: -15px;
  }

  .para {
    font-size: 12px;
    transform: scale(0.85);
    /* margin-left: -40px; */
    font-weight: 200;
  }

  .leaf1 {
    visibility: hidden;
  }

  .typo {
    margin-left: 10px;
  }
}

.box {
  display: flex;
  flex-direction: row;
}

.p {
  font-size: small;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .last_container {
    transform: scale(0.6);
    padding-left: -100px;
  }
}
