@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@200;400;600;800&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Lexend', sans-serif;
}
.my_container{
    max-width: 1200px;
    width: 90%;
    margin: auto;
}
header{
    min-height: 100vh;
}
.siderBar{
    width: 100%;
    height: 100%;
}
.sidebar-items{
    width: 247px;
    height: 760px;
}
.sidebar-items a span{
    color: #7000FF;
}
.main-element-modelun{
    /* background: url("/modelUn/background.svg"); */
    background-size: 70% 70%;
    background-repeat: no-repeat;
    background-position: center;
}
.model-un-slider, .model-un-innerleftItem{
    width: 70%;
}
.model-un-innerRightItem{
    width: 30%;
}
.model-un-innerleftItem h1{
    font-family: 'Lexend', sans-serif;
    font-size: 36px;
    font-weight: 700;
}
.model-un-innerleftItem p{
    font-family: 'Cabin', sans-serif;
    font-weight: 700;
    font-size: 17px;
    color: #373737;
}
.left-un-link{
    font-size: 17px;
    font-weight: 700;
    color: #7000FF;
    text-decoration: none;
}
.right-un-link{
    font-family: 'Cabin', sans-serif;
    color: #373737;
    font-size: 17px;
    text-decoration: none;
}
.modelun-slider-dots span{
    height: 8px;
    width: 8px;
    background: #6100FF;
    display: inline-block;
    border-radius: 50%;
    margin: 0 0.2rem;
}
.model-active-event{
    width: 100%;
    padding-left: 15rem;
}
.model-main-rightSiderbar p{
    font-size: 18px;
    font-weight: 600;
}
.model-main-rightSiderbar{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}
.model-sider-items{
    box-shadow: 0 20px 60px #eae6f183;
    border-radius: 23px;
}

/* ===============univercity section======== */

.modelun-univercitys{
    width: 70%;
}
.modelun-univer-items{
    box-shadow: 0 20px 60px #D0C8E2;
    border-radius: 30px;
    width: 237px;
}
.modelun-univer-items img:nth-child(1){
    border-right: 1px solid silver;
    padding-right: 1rem;
}
.institute-imgitems{
    width: fit-content;
}
.miniimagpara{
    font-size: 5px;
    font-weight: 600;
}
.univercitypara{
    font-size: 12px;
}
.cardPara{
    font-size: 10px;
    font-weight: 600;
}
.cardThemePara{
    font-size: 11px;
}
.interPara{
    color: #8000FF;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}
.registrationPara{
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}
.modelun-Date{
    color: #6A4D01;
    font-size: 23px;
    font-weight: 600;
}
.modelun-Days{
    font-size: 10px;
    font-weight: 300;
}
.eventModel-para{
    font-size: 12px;
    font-weight: 600;
}
.modelunDatepara{
    font-size: 17px;
    font-weight: 600;
    color: #1C016A;
}
.modelCardLastPara{
    font-weight: 600;
    font-size: 10px;
    color: #1C016A;
}
.modelunCard-buttons button:first-child{
    background: #7000FF;
    border: none;
    color: white;
    font-size: 11px;
    border-radius: 8px;
    padding: 0.5rem 0.7rem;
    font-weight: 600;
}
.modelunCard-buttons button:last-child{
    background: #fff;
    border: none;
    color: #7000FF;
    font-size: 11px;
    border-radius: 8px;
    font-weight: 600;
    padding: 0.5rem 0.7rem;
}

/* ===============what is yuva section============= */

.Yuva-main-Content{
    width: 70%;
}
.Yuvarightsiderbar{
    width: 30%;
    font-family: 'Inter', sans-serif;
    position: relative;
    top: -15px;
}
.yuva-heading{
    width: 447px;
    height: 145px;
    position: relative;
    display: grid;
    place-items: center;
}
.trending-yuva-blogs{
    margin-bottom: 25px;
}
.yuva-heading img:nth-child(2){
    bottom: -5px;
}
.yuva-heading > p{
    font-weight: 600;
    font-size: 30px;
}
.yuva-box-shadow{
    border-radius: 23px;
    box-shadow: 0 20px 60px #EAE6F1;
}
.yuva-box-shadow p:nth-child(1){
    font-size: 17px;
    font-weight: 600;
}
.yuva-box-shadow p:nth-child(2){
    font-size: 14px;
    font-weight: 400;
}
.yuva-progressbar{
    background: #EFECFF;
    height: 47px;
}
.yuvaBar{
    background: #EAE0FF;
    height: 100%;
    position: absolute;
    z-index: 1;
}
.livePoll-btns button{
    background: #E3D6FF;
    color:#360161;
    border: none;
    border-radius: 6px;
    padding: 0.5rem 1.5rem;
}
/* ================trending heading right sider bar ================ */

.tending-heading > p:nth-child(1){
    font-weight: 700;
}
.tending-heading > p:nth-child(2){
    font-size: 11px;
    font-weight: 700;
    color: #999999;
}
.trending-innnercontent p:nth-child(1){
    font-size: 10px;
    font-weight: 700;
}
.trending-innnercontent p:nth-child(2){
    font-size: 7px;
    font-weight: 500;
    color: #7D7D7D;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.treding-profile{
    font-size: 7px;
    font-weight: 400;
    color: #C8C8C8;
}
.treingImg{
    width: 130%;
}

.whatuva-content p:nth-child(1){
    font-size: 20px;
}
.whatuva-content p:nth-child(2){
    font-size: 19px;
    color: #231FFF;
}
.whocan-contents p:nth-child(1){
    font-size: 30px;
    font-weight: 600;
}
.whocan-contents p:nth-child(2){
    font-size: 17px;
    font-weight: 400;
}
.greenRectangle{
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-10%,50%);
}
.greenRectangles{
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(25%,50%);
}
.model-studentpara{
    font-weight: 600;
    font-size: 11px;
    position: absolute;
    text-align: center;
    color: #fff;
    bottom: -25%;
}
.model-studentparas{
    font-weight: 600;
    font-size: 11px;
    position: absolute;
    text-align: center;
    color: #fff;
    bottom: -25%;
    left: 50%;
    transform: translate(-50%);
}
/* ===================pathway to engagement============ */

.pathway-engage > p{
    font-weight: 600;
    font-size: 29px;
}
.engage-boxs{
    width: 181px;
    height: 181px;
    border-radius: 21px;
    box-shadow: 0px 4px 60px 0px #D8E1EE80;
    background: #5200FF1A;
}

/* =================how to participate section========================== */

.color-light-blue{
    color: #0061BB;
}
.participateContent > p{
    font-size: 13px;
}
.participateContent > button{
    color: #00046C;
    background-color: #C6C8FF;
    border-radius: 5px;
    border: none;
    padding: 0.4rem 1.5rem;
    font-size: 11px;
}
.fs-20px{
    font-size: 20px;
}

/* ===================what they will learn section design ==================
*/

.whatlearnpara{
    color: #7E7E7E;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.whatlearnContens p:nth-child(2){
    font-size: 13px;
    color: #A3A3A3;
    font-weight: 500;
}
.poll-para{
    display: inline-block;
    white-space: nowrap; 
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 200;
}
.z-index{
    z-index: 200;
}
.yuva-progressbar:hover .z-index .poll-para{
    white-space: wrap;
    overflow: visible;
    text-overflow: clip;
}
.h-fit{
    min-height: 50px;
    transition: all 0.5s ease;
}
.h-fit:hover{
    height:fit-content;
    overflow: auto;
}
.poll-bg{
    opacity: 1;
    cursor: pointer;
}
.poll-bg:hover {
    opacity: 0.5;
}
.trending-content{
    background-color: white;
}


@media (max-width:992px) {
    .model-un-slider, .model-un-innerleftItem{
        width: 100% !important;
    }
    .modelun-univercitys{
        width: 100% !important;
    }
    .Yuva-main-Content{
        width: 100% !important;
    }
    #enroll{
        width: 100% !important;
    }
    .model-un-innerleftItem h1{
        font-size: 30px !important;
        text-align: center;
    }
    .model-active-event{
        padding-left: 1rem;
    }
}

@media (max-width:522px) {
    .yuva-heading{
        width: 70% !important;
        height: 100px;
    }
    .yuva-heading p{
        font-size: 15px !important;
    }
    .model-active-event{
        padding-left: 1rem;
    }
}