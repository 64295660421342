.discussionBoardScroll::-webkit-scrollbar {
  display: none;
}
.fs-12{
  font-size :12px;
  font-weight: 400;
}
.fs-14{
font-size: 14px;
font-weight: 600;
}
.btn-save-create{
  text-align: center;
  height: 41px;
  background: hsl(267, 100%, 94%);
  color: #7700FF;
  border-radius: 8px;
  font-weight: 600;
  font-size: 21px;
  width: 100%;
  max-width: 180px;
}
.discTitle{
 color: #3A3A3A;
 font-size: 19px;
 font-weight: 500;
}
.line {
  background-color: #c6c6c6;
    border-radius: 3px;
    content: '';
    display: block;
    flex: 1 1;
    width: 1px;
    color: #b7b6b6;
   right:27px
}
.discError{
  font-size: 12px;
  color: red;
  font-weight: 400;
}

/* Message and comment section */
.comment-thread-container {

  display: block;
  /* width: 100%; */
  left: 0;
  transition: left 0.4s ease 0s;
}

.comment-thread {
  position: relative;
  /* width: 90%; */
  border-radius: 2px;
  border: solid 1px transparent;
  z-index: 10; 
}
.comment-initials-vline {
  position: absolute;
  width: 1px;
  left: 26px;
  height: 72%;
  background: green;
  top: 50px;
}

/* Container for comment initials, sender name, and comment text */
.comment-message-block {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  min-height: 50px;
  padding: 6px;
  padding-left: 2px;
  align-items: top;
  background-color: aqua;
}


.comment-initials-container {
  position: relative;
  left: 0;
  top: 1px;
  display: inline-block;
  z-index: 20;
}

.comment-message-container {
  display: inline-block;
    position: relative;

    max-height: 59px;
    overflow: hidden;
    padding-left: 10px;
    padding-bottom: 4px;
    max-height: none;
    overflow: hidden;
    -moz-user-select: text;
    -webkit-user-select: text;
}

.comment-message-text {
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
 
  color: #262626;
}

/* thread hover state */
.comment-thread-container:hover,
.comment-thread-container.hover,
.comment-thread-container.active {
  /* .comment-thread {
    box-shadow: 0 0 8px 0 rgba(51, 60, 78, 0.07);
    border: solid 1px rgba(50, 65, 95, 0.12);
  } */
  .comment-message-block {
    background: white;
  }

  .show-more-replies {
    background: white;
  }
}

/* ignore */
.comments-thread-list {
  position: relative;
  border: 0;
  zoom: 70%;
}

.screen-reader-text {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  color: transparent !important;
  background: none !important;
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.DisName{
  font-size: 13px;
  font-weight: 700;
  color: #000000;
}
.DisPara{
  font-size: 15px !important;
  font-weight: 500;
  color: #292929;
  text-align: justify;
}
.DisPointHead{
  font-size: 13px !important;
  font-weight: 400;
  color: #c400ea;
  text-align: justify;
}

.DisVPoint{
  font-size: 15px !important;
  font-weight: 600;
  color: #4a00ff;
  text-align: justify;
}
.comment-message-text p {
  margin:0
}

.DisChat{
  font-size: 13px !important;
  font-weight: 500;
  color: #292929b8;
  max-width: 72%;
  text-align: justify;
  background: #f3f3f3;
  display: inline;
  padding:12px 10px ;
}

.DisPoint{
  font-size: 14px !important;
  font-weight: 500;
  color: #6b3636;
  text-align: justify;
}

/* ==========  Main discussion board scrollbar style  ===============*/
.scroll-discussion-board{
  max-height: 400px;
  overflow: scroll;
 
}
.scroll-discussion-board::-webkit-scrollbar {
height: 10px;
}
.scroll-discussion-board::-webkit-scrollbar-thumb{
  border-radius: 6px;
  background-color: #CAC5FD;
}
.sscroll-discussion-board::-webkit-scrollbar-track {
  background-color: #f2eeee;
}

/* =========== Discussionboard checkbox styles ================  */
.mentions__mention {
  color: green !important;
  font-style: italic; 
}

.color-orange{
  color:#ffa803;
}


.discussionBoardMedalPosition{
    height: 42px;
    top: 0;
    left: 0;
    z-index: 0;
}

.discussion-first-rank-card{
  background: linear-gradient(90deg, rgba(217,225,78,1) 0%, rgba(244,246,220,1) 49%, rgba(254,255,79,1) 100%);

}

.discussion-second-rank-card{
background: linear-gradient(90deg, rgba(83,146,138,1) 0%, rgba(220,246,242,1) 49%, rgba(166,197,200,1) 100%);

}
.discussion-third-rank-card{
  background: linear-gradient(90deg, rgba(149,97,55,1) 0%, rgba(246,228,220,1) 49%, rgba(200,184,166,1) 100%);
}
.discussion-first-rank-span{
  color:#d7b500
}
.discussion-second-rank-span{
  color:#8d8d8d
}.discussion-third-rank-span{
  color:brown
}

.overflow-hidden-discussion{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}