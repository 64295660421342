.custom-accordion-option {
    margin-bottom: 10px;
    max-width: 100%;
}

.custom-accordion-heading {
    font-size: 1rem;
    font-weight: 400;
    padding: 10px;
    background-color: #FFFFFF;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: wrap;
}

.subtopic-item {
    background-color: #F0F0F0;
    color: black;
    border-radius: 10px;
    margin-bottom: 10px;
}

.subtopic-item .MuiSvgIcon-root {
    margin-right: 10px;
}