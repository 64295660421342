k.bannerImage{
    border-radius: 25px;
    height: 450px;
    max-height: 480px;
}
.download_card{
    height: 101px;
    width: 101px;
}
.downloadCardcolor{
    background: #F7F2FF;
}
.dashboardIcon{
    width: 45px !important;
    height: 45px  !important;
    color: #6A03DD  !important;
}
img.dashboardIcon{
    width: 45px !important;
    height: 45px  !important;
    color: #6A03DD  !important;
    filter: hue-rotate(0deg);
}
.dashboardFeatureTitle{
    font-size: 15px;
}
.iconDisableColor{
    background: rgb(224 224 224 / 81%);
    height: 68px;
    width: 68px;
    opacity: 0.2;
  cursor: not-allowed;
}
.iconEffect{
    height: 68px;
    width: 68px;
    background: #F7F2FF;
}
.iconEffect:hover{
    background-color:#e5d7ff;
    transition: all 0.5s;
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}
.discussion_borad{
    animation: grow 1s;
}

.ActivitiesAds{
    background: linear-gradient(rgb(142, 63, 204) 0%, rgb(143, 5, 96) 73%);
    min-height: 283px;
}
img.galleryImageIcon{
    width: 45px !important;
    height: 45px !important; 
}

@keyframes grow {
    0% { transform: scale(.2); }
  100% { transform: scale(1); }
}
/* Hover Effect dashboard icon */
.iconEffect{
    transition: all 0.7s ease-out;

}

.EventCard{
    border :2px solid #F1E5FF ;
    cursor: pointer;
    transition: all 0.5s;
}
.DownloadImages {
    width: 65px;
    height: 65px;
    position: relative;
    bottom: 7px;
    right: 7px;
}
.EventCard:hover{
    border:2px solid #FFE7AA;
    transform: scale(1.07);
    transition: all 0.3s;

}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.galleryIcon.css-i4bv87-MuiSvgIcon-root {
    color: #782fff !important;
    font-size: 45px !important;
    font-weight: 600;
}

.largeSlider{
    background-color: rgb(206, 205, 205);
    border-radius: 30px;
    padding:1rem;
}
.sliderpara{
    position: relative;
    top: -20px;
}
.sliderInnerLeft{
    position: relative;
}
.sliderInnerLeft img:nth-child(1){
    position: absolute;
    left: 5%;
    top: -3%;
    width: 30%;
}
.sliderInnerLeft img:nth-child(2){
    position: absolute;
    left: -10%;
    top: 60%;
    width: 110%;
    transform: translateY(-50%);
    height: 300px;
}
/* .sliderInnerRight{
    position: relative;
}
.sliderimg{
    position: sticky;
} */
.sliderbtn{
    background-color: orange;
    color: white;
    border-radius: 50px;
    box-shadow: -5px -5px #ffe600 inset;
}





@media screen and (max-width: 580px) {
    .bannerImage {
        border-radius: 25px;
        height: 250px;
        max-height: 480px;
    }
    .iconEffect {
        height: 35px;
        width: 35px;
    }
    .iconDisableColor {
        height: 35px;
        width: 35px;
    }
    .dashboardIcon {
        width: 25px !important;
        height: 25px !important;
    }
    .dashboardFeatureTitle{
        font-size: 11px;
    }
    img.dashboardIcon {
        width: 25px !important;
        height: 25px !important;
    
    }
    .download_card {
        height: 50px;
        width: 50px;
    }
    .DownloadImages {
        width: 35px;
        height: 35px;
        position: relative;
        left: -18px;
        bottom: 17px;
    }
    .ActivitiesAds{
        background: linear-gradient(rgb(142, 63, 204) 0%, rgb(143, 5, 96) 73%);
        min-height: 200px;
    }
}

#loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -20px;
    top: 30%;
    margin-top: -20px;
    position: absolute;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border:  solid 4px transparent;
    border-top-color:  #007bdf !important;
    border-left-color: #007bdf !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}

/* .nipamPopup{
    position: relative;
    top: 10px;
    left: 10px;
}
@media screen and (max-width: 580px){
    .nipamPopup{
        position: relative;
    }
} */
