.carbon-option-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.carbon-option-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2356fe;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
}

.carbon-option-heading h2 {
    color: white;
    margin: 0;
    font-size: 24px;
}

.carbon-option-heading .back-arrow {
    position: absolute;
    right: 10px;
    cursor: pointer;
    font-size: 1.4r em;
}

.carbon-option-heading .back-arrow:hover {
    width: 50px;
}

.carbon-option-questions {
    padding: 1rem;
}

.question-wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.subheading {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.questions-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.option {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 10px;
    cursor: pointer;
    transition: border 0.3s;
}

.option:hover {
    box-shadow: 0px 0px 91.98px 0px rgba(141, 139, 139, 0.15);
}

.option.selected {
    border-color: #2356fe;
}

.option .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118.51px;
    height: 118.51px;
}

.option .img-wrapper img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    box-shadow: 0px 0px 91.98px 0px rgba(35, 86, 254, 0.15);

    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px; */
    padding: 1rem;
}

.option h4 {
    font-size: 14px;
    font-weight: 300;
}


.question-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: 3px solid #2356fe;
    padding: 1rem;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
}

.input-container {
    width: 80%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 2px solid grey;
    padding: 8px;
    font-size: 1.5rem;
    color: grey;
    cursor: pointer;
}

.question-form label {
    max-width: 600px;
    width: 100%;
}

.input-container input {
    flex: 1;
    border: none;
    padding: 8px;
    margin: 0 8px;
    outline: none;
    width: 100%;
    font-size: 14px;
    color: black;
}

.helper-text {
    width: 80%;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
}

.calculate-btn {
    background-color: #2356fe;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.required {
    color: red;
}

.calc-result-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 55vh;
}

.calc-results-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    flex: 1;
}

.calc-result-card {
    background-color: #EBF0FF;
    color: #2356FE;
    border-radius: 15px;
    padding: 1rem;
    flex: 1 1 calc(33.333% - 15px);
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.calc-result-card h3 {
    margin-bottom: 1rem;
    color: #2356FE;
    font-weight: 600;
}

.calc-result-card p {
    font-weight: 600;
    font-size: 40px;
    margin: 0;
}