.options-progress-details-container .action-calculator {
    position: relative;
    background-color: #F6F8FF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    border-radius: 25px;
    border: 5px solid #2356FE;
    /* max-width: 820px; */
    min-width: 300px;
    min-height: 610px;
    overflow: hidden;
}

.action-calculator .heading {
    width: 95%;
    padding: 1vw;
    min-height: 79.22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 77.5px;
    align-self: flex-end;
    background-color: #2356FE;
    color: #FFFFFF;
    box-shadow: inset 0px -5.17px 20.67px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 3.44px 29.28px 0px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-indent: 12px;
}

.action-calculator .questions-box {
    position: relative;
    width: 90%;
    box-shadow: 0px 9.47px 37.89px -10.33px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* text-align: center; */
    align-items: center;
    padding: 1rem;
    gap: 1rem;
}

.action-calculator .progress-container {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 90%;
}

.action-calculator .progress-bar-bg {
    width: 100%;
    background-color: #F0F0F0;
    height: 20px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
}

.action-calculator .progress-bar {
    height: 100%;
    background-color: #28a745;
    transition: width 0.3s ease;
}

.action-calculator .questions-box h3 {
    width: 100%;
    font-size: 1.25rem;
    /* font-size: 1.75rem; */
    font-weight: 400;
    /* color: #2356FE; */
}

.action-calculator .questions-box .img-wrapper {
    width: 120px;
    height: 120px;
}

.action-calculator .questions-box .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.action-calculator .options-container {
    width: 90%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
}

.action-calculator .options-container .options-wrapper {
    display: flex;
    gap: 1rem;
    margin: auto;
}

.action-calculator .options-container .options-wrapper .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border: none;
    background-color: transparent;
    color: #2356FE;
    cursor: pointer;
}

.action-calculator .options-container .options-wrapper .option:hover {
    font-weight: 700;
}

.action-calculator .options-container .options-wrapper .option .img-wrapper {
    background-color: #FFFFFF;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}

.action-calculator .options-container .options-wrapper .option .img-wrapper img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
}

.action-calculator .options-container .change-btn {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    flex-wrap: wrap;
    font-size: 12px;
}

.action-calculator .options-container .change-btn span {
    color: white;
    background-color: #2356FE;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.action-calculator .options-container .change-btn span:hover {
    box-shadow: 0 0 0 5px rgba(35, 86, 254, 0.5);
}

@media screen and (max-width:800px) {
    .action-calculator .options-container .options-wrapper .option .img-wrapper {
        width: 70px;
        height: 70px;
    }

    .action-calculator .questions-box h3 {
        font-size: 1rem;
    }
}

@media screen and (max-width:625px) {
    .action-calculator .heading {
        font-size: medium;
    }

    .action-calculator .options-container .options-wrapper .option .img-wrapper {
        width: 50px;
        height: 50px;
    }
}