.challengehead h2{
    font-family: 'Luckiest Guy', cursive;
    font-size: 22px;
    background: -webkit-linear-gradient(#44856B, #77934C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 1rem 0;
}
.challengehead img:first-child{
    height: 70px;
    width: 150px;
}
.challengehead p img{
    display: inline-block;
    height: 35px !important;
    width: 35px !important;
}
.challengehead div{
    width: 100%;
}
.challengehead div h4{
    font-size: 22px;
    font-weight: 600;
}
.challnDays{
    background-color:#F8F8F8;
    border-radius: 19px;
    cursor: pointer;
}
.challengehead p{
    font-weight: 600;
}
.challengehead p span{
    color: #997700;
}
.daycredits {
    color: #D0A611;
    font-weight: 600;
    width: 100%;
}
.dayVal{
    background-color: 
    #FF9900;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    display: grid;
    place-items: center;
    border-radius: 19px;
    line-height: 35px;
    width: 15%;
    height: 151px;
}
.daypara{
    width: 85%;
}
.daypara p:first-child{
    color: #FFc700;
}
.daypara p:last-child{
    color: #8e8e8e;
}
.step{
    background-color:#8F00FF;
    padding: 0 3rem !important;
    font-size: 18px;
    font-weight: 600;
    height: 43px;
    border-radius: 11px;
}

/* =======================todayChallenComponentDesign============= */

.upload{
    border: 2px dashed #E4E4E4;
    padding: 3rem;
    border-radius: 19px;
}
.upload input{
    opacity: 0;
    cursor: pointer;
    width: 100%;
    display: inline;
    width: 25%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 19px;
}
.upload span{
    color: blue;
    text-decoration: underline;
}
.experience p:first-child{
    color: #6B6B6B;
}
.experience input{
    border-radius: 19px;
    padding: 1rem 0.5rem;
    width: 100%;
    border: none;
    margin-bottom: 1rem;
}
.expbtns button:first-child{
    background: #E9E9E9;
    padding: 0.5rem 3rem;
    color:#868686;
    font-weight: 600;
    border-radius: 10px;
}
.expbtns button:nth-child(2){
    background: #46633C;
    color:white;
    font-weight: 600;
    padding: 0.5rem 3rem;
    border-radius: 10px;
}
.backArrow{
    cursor: pointer;
}
@media (max-width:768px) {
    .dayVal{
        width: 40% !important;
    }
    .sm-fs-18px{
        font-size: 18px;
    }
    .sm-fs-10px{
        font-size: 14px !important;
    }
    .sm-w-30{
        width: 30% !important;
    }
    .sm-w-40{
        width: 40% !important;
    }
    .sm-w-50{
        width: 50%;
    }
    .sm-w-60{
        width: 60% !important;
    }
}
@media (min-width:768px) {
    .daypara{
        display: flex;
        justify-content: space-between;
    }
    .daypara div:nth-child(1){
        flex:5 1;
    }
    .daypara div:nth-child(2){
        flex:1 1;
        display: grid;
        place-items: center;
    }
    
}