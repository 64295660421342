@media (max-width:768px) {
    .sm-wfull{
      width: 100% !important;
    }
  }

  @media (max-width: 656px){
    .inneritems{
      padding:0.1rem 1rem ;
    }
  }
  @media (max-width: 656px){
    .popedelement{
      padding:2rem 0.6rem ;
    }
  }
  @media (max-width: 426px){
    .popedelement{
      width: 95%;
    }
    .inneritems p:nth-child(1){
      font-weight: 700;
      color: #675D00;
      font-size: 17px;
    }
    .inneritems p:nth-child(2){
      font-size: 17px;
      font-weight: 400;
    }
    .collegeItems p:nth-child(1){
      font-size: 15px;
      font-weight: 600;
      color: #2D008D;
    }
    .matchFoundPara{
      font-size: 18px;
      font-weight: 600;
    }
  }