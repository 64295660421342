.carbon-heading{
  font-size: 32px;
}

@media (max-width: 321px){
  .carbon-heading{
    font-size: 18px;
    font-weight: bold;
  }
}
@media (max-width: 426px){
  .carbon-heading{
    font-size: 17px;
    font-weight: bold;
  }
}

.fw-600-fs-32px{
    font-weight: 600;
    font-size: 32px;
}
.fw-400{
    font-weight: 400;
}

.fw-600-fs-24px{
    font-weight: 400;
    font-size: 24px;
}

.fs-24px{
    font-size: 24px;
}
.fs-21px{
    font-size: 21px;
}
.fs-32px{
    font-size: 32px;
}

.br-12-5px{
    border-radius: 12.5px;
}

.input-handle{
    width: 280px;
    height: 57px;
    border-radius: 12px;
    
}

.abosoluteImg1{
    position: relative;
}

.arrowLeftMain{
  position: absolute;
  top: 270px;
  left: 51px;
}

.arrowLeftMainDiabled{
  position: absolute;
  top: 288px;
  left: 74px;
}

.arrowRightMain{
  position: absolute;
  top: 310px;
  left: 1118px;
}


.abosoluteImg2{
    position: relative;
}

.arrowLeftMainmediumscreen{
    position: absolute;
    top: 382px;
    left: 5px;
}

.arrowLeftDiabledmediumscreen{
    position: absolute;
    top: 400px;
    left: 38px;
}


.arrowRightMainmediumscreen{
    position: absolute;
    top: 400px;
    left: 800px;
}

.inp-label {
    display: block;
    position: relative;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .inp-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FAEFFF;
    border: 4px solid #D9D9D9;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .inp-label:hover input ~ .checkmark {
    /* background-color: #AD00FF; */
  }
  
  /* When the checkbox is checked, add a blue background */
  .inp-label input:checked ~ .checkmark {
    background-color: #AD00FF;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .inp-label input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .inp-label .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: 1px solid #D9D9D9;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .search-handle {
    width: 70%;
    height: 57px;
    border-radius: 12px;
    border:2px solid #4200FF;
    outline: none;
}
.search-handleList{
  width: 65%;
}
.databox{
  width: 100%;
  height: 179px;
}

.btn-outline-carbon-custom {
  color: #ffffff;
  background-color: #d600ff;
  border-color: rgb(60, 0, 88);
  padding: 6px 14px;
  transition: 0.5s;
  cursor: pointer;
}
.btn-outline-carbon-active {
  color: #ffffff;
  background-color: #1e70ff;
  border-color: rgb(60, 0, 88);
  padding: 6px 14px;
  transition: 0.5s;
  cursor: pointer;
}

.btn-outline-carbon-custom:hover {
  color: #ffffff;
  background-color: #e14cff;
  border-color: rgb(60, 0, 88);
}

@media screen and (max-width: 580px) {
  .search-handle {
    width: 100%;
}
.search-handleList{
  width:90%;
}
}

@media (max-width: 501px){
  .margin-sm-screen{
    margin: auto;
  }
}