.subscribe{
    background-color: #7000FF;
    border-radius: 33px;
    font-family: 'Lexend', sans-serif;
}
.subscribe h3{
    font-size:36px;
}
.subscribe form input{
    border-radius: 11px;
    border: none;
    padding: 0.5rem 1rem;
    color: #7000FF;
    outline: none;
}
.subscribe form input:first-child{
    width: 40%;
}
@media (max-width:768px) {
    .subscribe h3{
        font-size: 25px;
        font-weight: 600;
    }
    .subscribe form input:first-child{
        width: 80% !important;
    }
}