@font-face {
    font-family: 'Samarn';
    src: url('/public/fonts/SAMARN.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.role {
    display: inline-block;
    opacity: 0;
    position: relative;
    bottom: -10px;
    transition: opacity .5s ease-in, bottom 1s ease-in-out;
}
.role.visible {
    opacity: 1;
    bottom: 0;
}
.Nt{
	text-transform: uppercase;
	background: linear-gradient(to right, #ea6085 0%, #2356fe 100%);
    background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    font-size:45px;
}

.text {
	text-transform: uppercase !important;
	background:linear-gradient(to right, #ea6085 0%, #2356fe 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;	
}
.parantForm{
    position: relative;
 width: 75%;
 max-width: 550px;
display: flex;
justify-content: center;
align-items: center

}
.window{
    width: 90%;
    padding:10px;

   
    /* border:1px solid rgb(206, 173, 173); */
    border-radius:20px;
    backdrop-filter:blur(20px);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:#000000;
  }
  .cirTopleft{
    width: 100px; 
    height: 100px;
    right: 82%;
    bottom: 85%; 
    z-index: -1;
    background: linear-gradient(to bottom, #2356FE 0%, #153398 100%); 
  }
  .cirTopright{
    width: 250px;
    height: 250px;
    right: -3%;
    bottom: 60%; z-index: -1;
   background: linear-gradient(to bottom, #2356FE 0%, #153398 100%);
  }
  .cirBotLeft{
    width: 150px; 
    height: 150px; 
    right: 75%;
    bottom: -8%;
     z-index: -1;
     background: linear-gradient(to bottom, #2356FE 0%, #153398 100%)
  }
  .cirBotRight{
    width: 150px; 
    height: 150px; 
    right: 6%;
    bottom: 1%;
     z-index: -1;
     background: linear-gradient(to bottom, #2356FE 0%, #153398 100%)
  }
  .loginbtn:hover{
  background: #fff;
  color:#2356FE;
  border: 1px solid #2356FE;
  transition: all 0.5s ease-in;
  }
  .sendEmailbtn:hover{
    background: transparent !important;
    color:#2356FE !important;
    border: 1px solid #2356FE !important;
    transition: all 0.5s ease-in;

  }
  
  .card {
    border: none;
   background: #fff;
   height:556px;
   max-width: 455px;
    border-radius: 15px;
    box-shadow:  0px 16px 118px #0000001a;
    position: relative;
    padding:0 50px;
    z-index: 1;
  }
  
  .card-title {
    margin-top: 50px;
    color: #007bff;
  }
  
  .card-subtitle {
    margin-top: 10px;
    font-size: 15px;
  }
  .txtbefore{
    margin-right: 5px;
    display: inline-block;
    font-size: 55px;
    color: #2356FE;
    position: relative;
    top: 27px;
  }
  .txtafter{
    display: inline-block;
    font-size: 55px;
    color: #2356FE;
    position: absolute;
    
  }
  
  .background-circle {
    position: absolute;
    width: 420px;
    height: 420px;
    background-color: #007bff;
    border-radius: 50%;
    top: 2%;
    left: -27%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .background-circle1 {
    position: absolute;
    width: 290px;
    height: 290px;
    background-color: #007bff;
    border-radius: 50%;
    top: 10%;
    left: 0%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .background-circle2 {
    position: absolute;
    width: 202px;
    height: 202px;
    background-color: #007bff;
    border-radius: 50%;
    top: 20px;
    left: 0%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .backgroundcircle3 {
    position: absolute;
    width: 91px;
    height: 91px;
    background-color: #007bff;
    border-radius: 50%;
    top: 60%;
    left: 100%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .backgroundcircle4 {
    position: absolute;
    width: 45px;
    height: 45px;
    background-color: #007bff;
    border-radius: 50%;
    top: 40%;
    left: 110%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .backgroundcircle5 {
    position: absolute;
    width: 17px;
    height: 17px;
    background-color: #007bff;
    border-radius: 50%;
    top: 50%;
    left: 113%;

    z-index: -1;
  }
  .commas{
    color: #007bff;
  }
  .carouselItem {
    background: linear-gradient(to bottom, #2356FE 0%, #153398 100%); 
    transition: opacity 2s ease-in-out;
  }
  .animatedText {
    height: 200px;
    animation:fade 20s infinite;
-webkit-animation:fade 20s infinite;
  }
  .carouselItem.active .animatedText {
    opacity: 1;
    transform: translateY(0);
  }
  .slider {
   
    height: 50px;
    margin: 20px auto;
    position: relative;
    font-size: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 20px;
    opacity: 0;
  }
  .Niptitle{
    height: 88px;
    width: 600px;
    bottom: 76%;
    border-radius: 55px;
    position: absolute;
    box-shadow: 0 14px 44px #0000001A;
    background: linear-gradient(to bottom, #FFFFFF 0%, #F6F6F6 100%);
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .NipTxt{
    position: relative;
    bottom: 120%;
    font-size: 40px;
    font-weight: 600;
    color: #7F0DFF;
  }
 .NipDesc{
  position: relative;
  bottom: 75%;
  font-size: 30px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  padding: 0 87px;
 }
 .parantCardben{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 50px;
 }
 .childCardbenContainer{
  width: 33%;
  max-width: 330px;
 }
 .childCardben{
  display: flex;
  flex: 1 1 250px;
  width: 100%;
  max-width: 338px;
  margin-bottom: 25px;
 }

 .topribbon{
  background: #2356FE;
  transform: scaleX(1.02);
    border-radius: 10px;
    box-shadow: 0 14px 74px #00000059, inset 0 4px 44px #00000040;
 }
 .moduleHeading{
  left:50%;
  transform: translate(-50%, -48%);
  height:88px;
  padding: 10px 40px;
  text-align: center;
  width: 40%;
  border-radius:100px;
  box-shadow:0 14px 44px #0000001A;
  background:linear-gradient(180deg, #FFFFFF 100%, #F6F6F6 100%);
  color:#2356FE
 }
 @media screen and (max-width: 1400px) {
  .moduleHeading{
    width: 60%;
  }
}
@media screen and (max-width: 1400px) {
  .card{
    padding: 0;
  }
  .childCardbenContainer{
    width: 47%;
    max-width: 330px;
   }
}


  @media screen and (max-width: 500px) {
    .parantForm{
     position: relative;
     width:auto;
    }
    .cirTopleft{
        width: 75px; 
        height: 75px;
      }
      .cirTopright{
        width: 125px;
        height: 125px;
        bottom: 80%; 
      }
      .cirBotLeft{
        width: 100px; 
        height: 100px; 

      }
      .background-circle {
        display: none;
      }
      .background-circle1 {
        width: 180px;
        height: 180px;
        top: -18%;
        left: 75%;
      }
    .background-circle2{
        width: 155px;
        height: 155px;
        top: -21%;
        left: 20%;
      }
      .carouselItem {
        max-height: 300px;
        height: 276px;    
      }
      .Niptitle{
        width: 300px;
      }
      .NipTxt{
        bottom: 85%;
        font-size: 20px;
      }
      .NipDesc{
        position: relative;
       bottom: 8%;
       font-size: 16px;
       padding: 0 20px;
      }
      .childCardbenContainer{
        width: 100%;
       }
       .font-40 {
        font-size: 20px;
    }
    .moduleHeading{
      width: 85%;
    }
  }
 
