.mainDiv {
  padding-block: 10px;
  padding-inline: 50px;
  border: 10px solid black;
}

.innerDiv {
  padding-inline: 50px;
  padding-block: 20px;
  border: 2px solid #007bff;
  border-radius: 7px;
}

.EnrolmentTagP {
  text-align: center;
  font-size: 26px;
}

.generalTag {
  font-size: 20px;
  font-weight: 500;
}

.formUl {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputBox {
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  gap: 6px;
}
.inputLabel {
  font-size: 16px;
}
.inputField {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 2px black dotted;
  flex: 1;
  font-weight: bold;
  margin-bottom: 4px;
}

.inputRadioBox {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.inputRadio {
  display: flex;
  align-items: center;
}

.nodalInputBox {
  flex: 1;
}

.StudentStrength {
  flex: 1;
  margin-left: 5px;
}

.inputBoxProvided {
  display: flex;
  align-items: center;
  gap: 50px;
}

.btnBox {
  text-align: end;
}

.submitBtn {
  width: 200px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}
