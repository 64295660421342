.options-progress-details-container .challenge-completion {
  background-color: #FFFFFF;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 2vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  border-radius: 25px;
  border: 5px solid #2356FE;
  flex-wrap: wrap;
  /* max-width: 820px; */
  min-width: 300px;
}

.challenge-completion .left-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  z-index: 1;
}

.challenge-completion .left-area .img-wrapper {
  width: 100%;
  height: 168px;
  max-width: 358px;
}

.challenge-completion .left-area .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.challenge-completion .left-area h1 {
  font-size: 32px;
  color: #3F3F3F;
}

.challenge-completion .right-area {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  flex: 1;
}

.challenge-completion .right-area .details {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.challenge-completion .right-area .success-msg {
  color: #024A16;
}

.challenge-completion .right-area .sub-heading {
  color: #676767;
}

.challenge-completion .right-area .img-wrapper {
  position: relative;
}

.challenge-completion .right-area .img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.challenge-completion .right-area .primary-btn {
  background-color: #2356FE;
  border: none;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 600;
  align-self: flex-end;
  border-radius: 10px;
  padding: 10px 1rem;
  min-width: 150px;
  cursor: pointer;
  z-index: 1;
}

.challenge-completion .right-area .primary-btn:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 1024px) {
  .options-progress-details-container .challenge-completion {
    flex-direction: column;
  }
}