/* General Reset and Custom Styles */
/**/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

.dashboard-content-container {
  position: relative;
  display: flex;
  gap: 1rem;
  flex: 1;
  width: 100%;
  height: 91vh;
  overflow: hidden;
}

.options-progress-details-container {
  overflow: scroll;
  flex: 1;
  transition: transform 0.3s ease;
}

.options-progress-details-container .guidelines-wrapper {
  border-radius: 1rem;
  background-color: #f0f0f0;
  padding: 2rem;
}

.options-progress-details-container .guidelines-wrapper .guideline {
  font-weight: 600;
  font-size: 20px;
}

.options-progress-details-container.hide {
  transform: translateX(100%);
}

.clubPanelToggle {
  position: fixed;
  width: 50px !important;
  right: 0px;
  top: 45%;
  border-radius: 0px 0px 0px 0px !important;
}

.options-progress-panel {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  width: 200px;
}
.options-progress-panel .heading-wrapper-riasec {
  margin-top: 0.5rem;
  background-color: #2356fe;
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 60px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.options-progress-panel .heading-wrapper {
  background-color: #c7d4ff;
  box-shadow: 0px 4px 64px 0px rgba(35, 86, 254, 0.2);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 156px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

/* .options-progress-panel .heading-wrapper .main-heading {
  font-size: 30px;
  font-weight: 600;
} */

/* .options-progress-panel .heading-wrapper .sub-heading {
  font-size: 15px;
  font-weight: 300;
} */

.options-progress-panel .options-list {
  margin: 1rem 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.custom-accordion-option {
  margin-bottom: 15px;
  width: 100%;
}

.accordion-summary {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  max-width: 100%;
  padding: 0.5rem;
}

.accordion-details {
  background-color: #ffffff;
  color: black;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
}

.social-media-section {
  margin-top: 20px;
}

.social-icon {
  margin: 0 5px;
}

.author-name {
  color: white;
}

body.show-details .options-progress-details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

body.show-details .options-progress-details-container .toggle-button {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
}

body.show-details .options-progress-panel {
  display: none;
}

@media screen and (max-width: 1024px) {
  .options-progress-details-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
  }

  body.show-details .options-progress-details-container {
    transform: translateX(0);
  }

  .options-progress-panel {
    display: block;
    width: 100%;
  }
}
