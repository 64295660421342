.under-progress-container {
    position: relative;
    padding: 0.8rem;
    box-shadow: 0 3.4px 46.5px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    border: 5px solid #2356fe;
    display: flex;
    justify-content: center;
    height: 62.5vh;
    width: 100%;
    overflow: hidden;
}

.under-progress-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.under-progress-container h2 {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 10px 2rem;
    min-width: 180px;
    z-index: 1;
    user-select: none;
    transform: translateX(-50%);
    border: 5px solid #2356fe;
    border-top: 0;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #2356fe;
    color: white;
    text-align: center;
}