x:root {
    
  }

  .fw-700{
    font-weight: 700;
}
.fw-300{
    font-weight: 300;
}
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}

.fs-33px{
    font-size: 33px;
}
.fs-19px{
    font-size: 19px;
}
.fs-13px{
    font-size: 13px;
}
.fs-15px{
    font-size: 15px;
}
.fs-9px{
    font-size: 9px;
}
.fs-11-07px{
    font-size: 11.07px;
}
.fs-14px{
    font-size: 14px;
}
.fs-16-07px{
    font-size: 16.07px;
}
.fs-18-07px{
    font-size: 18.07px;
}
  .pro{
    background: linear-gradient(to right, #E1BA30 100%, #FACE32 65%);
    /* Add any other CSS properties you need for your <div> here */
  }
  .image-div{
    border: 10px solid transparent; 
    border-image: linear-gradient(to right, #AA79FC 0%, #6100FF 100%);
    border-image-slice: 1; 
    /* border-radius: 50%; */
  }

  .select-Tab-OPtion{
    color: "#737373";
    font-weight: 700;
    font-size: 15px;
  }
  
  .testimonial {
    width: 100%;
    max-width: 600px; /* Set a maximum width if needed */
    margin: 0 auto; /* Center the container */
  }

  .testimonialLeft {
    position: absolute;
    bottom:50%; /* Adjust as needed */
    left: -4%; /* Adjust as needed */
  }

  .testimonialRight {
    position: absolute;
    right: -2%; /* Adjust as needed */
    top: 8%; /* Adjust as needed */
  }

  @media (max-width: 426px){
    .testimonialLeft {
    position: absolute;
    bottom:69%; /* Adjust as needed */
    left: -8%; /* Adjust as needed */
  }

  .testimonialRight {
    position: absolute;
    right: -12%; /* Adjust as needed */
    top: 60%; /* Adjust as needed */
  }
  }

  .club-modal{
    margin: auto;
    margin-top: 82px;
    max-width: 45%;
  
   }
   @media (max-width: 1240px) {
    .club-modal {
      max-width: 60%;
    }
  }
   @media (max-width: 1030px) {
    .club-modal {
      max-width: 65%;
    }
  }
   @media (max-width: 991px) {
    .club-modal {
      max-width: 50%;
    }
  }
   @media (max-width: 600px) {
    .club-modal {
      max-width: 80%;
    }
  }

  .update-delete-btn{
    /* color: white; */
  }

  .update-delete-btn:hover{
    /* color: white; */
  }

  .update-delete-btn:hover{
    color: white;
    background: #9700de;
  }


  .scroll-comment-like-container {
    overflow-y: auto;
    scroll-behavior: smooth;
}
.scroll-comment-like-container::-webkit-scrollbar {
    /* height:     2px; */
    width: 2px;
    /* display: none; */
    border-radius: 6px; /* Set the border radius to make it rounder */
}

::-webkit-scrollbar-thumb{
    background-color: #cebef2;
}

.scroll-comment-like-container::-webkit-scrollbar-track {
    background-color: #f2eeee; /* Change the color on hover */
}

/* For post */
.scroll-postComment-container {
  overflow-y: auto;
  scroll-behavior: smooth;
}
.scroll-postComment-container::-webkit-scrollbar {
  /* height:     2px; */
  width: 2px;
  /* display: none; */
  border-radius: 6px; /* Set the border radius to make it rounder */
}

::-webkit-scrollbar-thumb{
  background-color: #cebef2;
}

.scroll-postComment-container::-webkit-scrollbar-track {
  background-color: #f2eeee; /* Change the color on hover */
}


/* Join CLUB */
.custom-btn-1 {
  color: #fff;
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-1 {
  background: #6100ff;
  line-height: 40px;
  padding: 0;
  border: none;
  box-shadow: 0 0 5px rgb(38, 0, 255);
}
.btn-1 .enter-Club {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-1:hover{
  background: transparent;
}
.btn-1:before,
.btn-1:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: rgb(38, 0, 255);
 box-shadow: 0 0 5px rgb(38, 0, 255);
}
.btn-1:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-1:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-1:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-1:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-1 .join-club:before,
.btn-1 .join-club:after {
  position: absolute;
  content: "";
  background: rgb(38, 0, 255);
  box-shadow: 0 0 5px rgb(38, 0, 255);
}
.btn-1 .join-club:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-1 .join-club:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-1 .join-club:hover:before {
  width: 100%;
}
.btn-1 .join-club:hover:after {
  width: 100%;
}
.btn-1:hover {
  color: black; /* Add this line to change text color to black on hover */
  background: transparent;
}



/* ENTER CLUB */
.custom-btn-6 {
  color: #fff;
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-6 {
  background: #9b5ffd;
  line-height: 40px;
  padding: 0;
  border: none;
  box-shadow: 0 0 5px rgb(70 168 246);
}
.btn-6 .enter-Club {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-6:hover{
  background: transparent;
}
.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: #9700de;
 box-shadow: 0 0 5px #9700de;
}
.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6 .enter-Club:before,
.btn-6 .enter-Club:after {
  position: absolute;
  content: "";
  background: #9700de;
  box-shadow: 0 0 5px #9700de;
}
.btn-6 .enter-Club:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-6 .enter-Club:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-6 .enter-Club:hover:before {
  width: 100%;
}
.btn-6 .enter-Club:hover:after {
  width: 100%;
}
.btn-6:hover {
  color: black; /* Add this line to change text color to black on hover */
  background: transparent;
}



  
/* UNFOLLOW BUTTON */
.custom-btn-3 {
  color: #fff;
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-3 {
  background: rgb(70 168 246);
  line-height: 40px;
  padding: 0;
  border: none;
  box-shadow: 0 0 5px rgb(70 168 246);
}
.btn-3 .unfollow-club {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:hover{
  background: transparent;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: rgb(70 168 246);
 box-shadow: 0 0 5px rgb(70 168 246);
}
.btn-3:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-3:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-3:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-3:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-3 .unfollow-club:before,
.btn-3 .unfollow-club:after {
  position: absolute;
  content: "";
  background: rgb(70 168 246);
  box-shadow: 0 0 5px rgb(70 168 246);
}
.btn-3 .unfollow-club:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-3 .unfollow-club:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-3 .unfollow-club:hover:before {
  width: 100%;
}
.btn-3 .unfollow-club:hover:after {
  width: 100%;
}
.btn-3:hover {
  color: black; /* Add this line to change text color to black on hover */
  background: transparent;
}


/* IGNORE BUTTON */
.custom-btn-4 {
  color: #fff;
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-4 {
  background: #cc6e6e;
  line-height: 40px;
  padding: 0;
  border: none;
  box-shadow: 0 0 5px #d66647;
}
.btn-4 .ignore {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-4:hover{
  background: transparent;
}
.btn-4:before,
.btn-4:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: #d66647;
 box-shadow: 0 0 5px #d66647;
}
.btn-4:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-4:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-4:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-4:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-4 .ignore:before,
.btn-4 .ignore:after {
  position: absolute;
  content: "";
  background: #d66647;
  box-shadow: 0 0 5px #d66647;
}
.btn-4 .ignore:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-4 .ignore:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-4 .ignore:hover:before {
  width: 100%;
}
.btn-4 .ignore:hover:after {
  width: 100%;
}
.btn-4:hover {
  color: black; /* Add this line to change text color to black on hover */
  background: transparent;
}


.comment-emoji-div {
  position: relative;
}
.emoji-Picker_div {
  position: absolute;
  top: -320px;
  right: 45px
}

@media (max-width: 520px) {
  .comment-emoji-div {
    position: relative;

  }
  .emoji-Picker_div {
    position: absolute;
    top: -380px;
    right: -21px
  }
}


.add-post-emoji-div {
  position: relative;
}
.post-emoji-div {
  position: absolute;
  top: -355px;
  right: 2px
}

@media (max-width: 520px) {
  .add-post-emoji-div {
    position: relative;

  }
  .post-emoji-div {
    position: absolute;
    top: -380px;
    right: -21px
  }
}

.mention-paragraph{
  background-color: #9700de;
}

.institute-teacher-student-name{
  font-weight: 700;
  font-size: 33px;
}


@media (max-width: 510px) {
  .institute-teacher-student-name{
    text-align: center;
    font-size: 24px;
  }
}
.qualification{
  font-size: 17px;
}
@media (max-width: 510px) {
  .qualification{
    text-align: center;
    font-size: 15px;
  }
}

.event-carbon-box{
  margin-top: 5px;
}

@media (max-width: 510px){
  .event-carbon-box{
    margin-top: 18px;
  }
}

.display-institute-role{
  position: relative;
  bottom: 22px;
}

.display-institute-role button{
  width: 96px;
  height: 30px;
  background-color: #FFEA79;
  border-radius: 8px;
  font-size: 12px;
  color: #583500;
  font-weight: 700;
}

.display-institute-address button{
  height: 30px;
  background-color: #FFEA79;
  border-radius: 8px;
  font-size: 12px;
  color: #583500;
  font-weight: 700;
}

.all-institute-data{
  margin-top: 25px;
}

.Infrastructure-data{
  margin-top: 8px;
}

.social-media-content{
  margin-top: 15px;
}

.teacher-events{
  /* margin-left: 100px; */
}

.subject-taught{
  /* margin-left: 29px; */
}

.teacher-experince{
  /* margin-left: 32px; */
}

.teacher-skills{
/* margin-left: 100px; */
display: flex;
flex-wrap: wrap;
gap: 5px;
}

.student-experience{
    
}

@media (max-width: 510px){
  .all-institute-data{
    margin-top: 22px;
  }
  .Infrastructure-div{
    margin-top: 22px;
  }

  .Infrastructure-data{
    margin-top: 8px;
  }
  .social-media-content{
    margin-top: 15px;
  }

  .display-none-smscreen{
    display: none;
  }
  .teacher-events{
    /* margin-left: 50px; */
  }
  .subject-taught{
    /* margin-left: 12px; */
  }
  .teacher-experince{
    margin-top: 10px;
    /* margin-left: 24px; */
  }
  .teacher-skills{
    margin-top: 10px;
    /* margin-left: 85px; */
  }
  .student-experience{
    margin-top: 10px;
  }
}

.other-account{
  position: relative;

}

.ava1 .ava2 .ava3 .ava4{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.acti{
  border: 1px solid red;
  /* font-weight: 15px; */
}

@media (max-width: 426px){
  .right-component-none{
    display: none;
  }
}

.scroll-tab-section {
  overflow-y: auto;
  scroll-behavior: smooth;
}
.scroll-tab-section::-webkit-scrollbar {
  height:     2px;
  /* display: none; */
  border-radius: 6px; /* Set the border radius to make it rounder */
}

::-webkit-scrollbar-thumb{
  background-color: #d5cbea;
}

.scroll-tab-section::-webkit-scrollbar-track {
  background-color: #f2eeee; /* Change the color on hover */
}
.profile-img-circular-bar{
  height:150px;
  width:150px
}
.profile-img-dimension{
  height:128px;
  width:128px;
}


/* media query */

@media only screen and (max-width: 500px) {
 
  .fs-33px {
    font-size: 26px;
}

.fs-15px {
  font-size: 15px !important;
}

  
}

@media only screen and (max-width: 400px) {
 
  .fs-33px {
    font-size: 18px;
}

.fs-15px {
  font-size: 12px !important;
}

  
}

.img-comment-avatar{
  height: 100%;
}

.comment{
 padding: 5px;
}

.user-contetnt{
  font-size: 15px;
}

.postcenter-time{
  color: grey;
  font-size: 14px;
}

@media (max-width: 426px){
  .postcenter-time{
    font-size: 10px;
  }
  .img-comment-avatar{
    height: 32px;
    width: 32px;
  }
  .icon-emoji-tag{
    width: 10px;
    height: 10px;
  }
  .hide-post-comment{
    display: none;
  }
  .user-contetnt{
    font-size: 12px;
  }
  .comment{
    padding: 5px;
    font-size: 14px;
  }
  .profile-img-circular-bar{
    height:125px;
    width:125px
  }
  .profile-img-dimension{
    height:107px;
    width:107px
  }
}
@media (max-width: 321px){
  .hide-alter-icon{
    /* border: 1px solid red; */
    display: none;
  }
}


.loading-dot-top {
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: #7e22ce;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  box-shadow: 
      0px 40px 0px 0px #7e22ce,
      0px -40px 0px 0px #ffffff,
      40px 0px 0px 0px #7e22ce,
      -40px 0px 0px 0px #ffffff,
      40px 40px 0px 0px #ffffff,
      -40px -40px 0px 0px #ffffff,
      40px -40px 0px 0px #ffffff,
      -40px 40px 0px 0px #ffffff;
  animation: load 3s infinite linear;
}

@keyframes load {
  0% {
      transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
      box-shadow: 
          0px 40px 0px 0px #7e22ce,
          0px -40px 0px 0px #ffffff,
          40px 0px 0px 0px #7e22ce,
          -40px 0px 0px 0px #ffffff,
          -40px -40px 0px 0px #7e22ce,
          40px 40px 0px 0px #ffffff,
          -40px 40px 0px 0px #ffffff,
          40px -40px 0px 0px #7e22ce;
  }

  50% {
      box-shadow: 
          0px -40px 0px 0px #ffffff,
          0px 40px 0px 0px #ffffff,
          -40px 0px 0px 0px #ffffff,
          40px 0px 0px 0px #7e22ce,
          -40px -40px 0px 0px #ffffff,
          40px 40px 0px 0px #ffffff,
          -40px 40px 0px 0px #ffffff,
          40px -40px 0px 0px #ffffff;
  }

  70% {
      box-shadow: 0px 0px 0px 0px #ffffff;
  }

  75% {
      box-shadow: 0px 0px 0px 0px #ffffff;
      height: 10px;
      width: 10px;
  }

  80% {
      transform: translate(0px, 0px) rotate(360deg);
      box-shadow: 0px 0px 0px 0px #7e22ce;
      background-color: #ffffff;
      height: 40px;
      width: 40px;
  }

  85% {
      transform: translate(0px, 0px) rotate(360deg);
      box-shadow: 0px 0px 0px 0px #7e22ce;
      background-color: #7e22ce;
      height: 40px;
      width: 40px;
  }

  90% {
      transform: translate(0px, 0px) rotate(360deg);
      box-shadow: 0px 0px 0px 0px #ffffff;
      background-color: #7e22ce;
      height: 10px;
      width: 10px;
  }

  95% {
      box-shadow: 
          0px 40px 0px 0px #ffffff,
          0px -40px 0px 0px #ffffff,
          40px 0px 0px 0px #7e22ce,
          -40px 0px 0px 0px #ffffff,
          40px 40px 0px 0px #ffffff,
          -40px -40px 0px 0px #ffffff,
          40px -40px 0px 0px #ffffff,
          -40px 40px 0px 0px #7e22ce;
  }

  100% {
      transform: rotate(360deg);
      box-shadow: 
          0px 40px 0px 0px #a80000,
          0px -40px 0px 0px #00cdcd,
          40px 0px 0px 0px #ffffff,
          -40px 0px 0px 0px #ffffff,
          40px 40px 0px 0px #dd0000,
          -40px -40px 0px 0px #ffffff,
          40px -40px 0px 0px #ffffff,
          -40px 40px 0px 0px #590606;
  }
}
.commentPost{
  transition: all 0.3s ease;
  padding: 8px 10px; /* Initial padding */
  background-color: transparent; 
  border-radius: 8px;
}
.commentPost:hover{
 background-color: rgb(222, 222, 222);
}
