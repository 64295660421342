/* General Reset and Custom Styles */
/**/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  font-family: "Poppins", sans-serif;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

.dashboard-content-container {
  position: relative;
  display: flex;
  gap: 1rem;
  flex: 1;
  width: 100%;
  height: 91vh;
  overflow: hidden;
}

.options-progress-details-container {
  overflow: scroll;
  flex: 1;
  transition: transform 0.3s ease;
}

.options-progress-details-container .guidelines-wrapper {
  border-radius: 1rem;
  background-color: #f0f0f0;
  padding: 2rem;
}

.options-progress-details-container .guidelines-wrapper .guideline {
  font-weight: 600;
  font-size: 20px;
}

.options-progress-details-container.hide {
  transform: translateX(100%);
}

.clubPanelToggle {
  position: fixed;
  width: 50px !important;
  right: 0px;
  top: 45%;
  border-radius: 0px 0px 0px 0px !important;
}

.options-progress-panel {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: scroll;
  width: 200px;
}

.options-progress-panel > .heading-wrapper {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2356FE;
    padding-top: 13px;
    padding-inline: 10px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-right: 20px;
}

.options-progress-panel .heading-wrapper > .main-heading {
    font-size: 30px;
    color: white;
}

.options-progress-panel .heading-wrapper > .sub-heading {
    font-size: 15px;
    color: white;

}

.options-progress-panel .options-list {
   margin-top: 7rem;
   overflow: hidden;
}

.custom-accordion-option {
    margin-bottom: 15px;
    width: 100%;
    box-shadow: none !important;
    border: none !important;
    border-top-right-radius: "1.5rem";
    border-bottom-right-radius: "1.5rem";
}

.accordion-summary {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    max-width: 100%;
    padding: 0.5rem;
}

.accordion-details {
    background-color: #ffffff;
    color: black;
    padding: 10px;
    max-width: 100%;
}

.social-media-section {
  margin-top: 20px;
}

.social-icon {
  margin: 0 5px;
}

.author-name {
  color: white;
}

body.show-details .options-progress-details-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

body.show-details .options-progress-details-container .toggle-button {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
}

body.show-details .options-progress-panel {
  display: none;
}

@media screen and (max-width: 1024px) {
  .options-progress-details-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
  }

  body.show-details .options-progress-details-container {
    transform: translateX(0);
  }

  .options-progress-panel {
    display: block;
    width: 100%;
  }
}
