.rowForImg {
    /* display: -ms-flexbox; */
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  .columnForImg {
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .columnForImg img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  @media screen and (max-width: 800px) {
    .columnForImg {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .columnForImg {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  