.carbon-container {
    padding: 0.8rem;
    box-shadow: 0 3.4px 46.5px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background-color: #FFFFFF;
    border: 5px solid #2356fe;
    display: flex;
    justify-content: center;
}

.carbon-options-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    width: 100%;
}

.carbon-options-wrapper .carbon-option {
    background-color: #2356fe;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 225px;
}

.carbon-options-wrapper .carbon-option:hover {
    background-color: #C7D4FF;
    color: #2356fe;
}

.carbon-options-wrapper .carbon-option:disabled {
    background-color: #EBF0FF;
    color: grey;
}