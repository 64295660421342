.my_container{
    max-width: 1200px;
    width: 90%;
    margin: auto;
}
header{
    min-height: 100vh;
}
.siderBar{
    width: 20%;
    height: 100%;
    box-shadow: 0 20px 60px #ebf2f470;
}
.main{
    width: 80%;
}
.sidebar-items{
    width: 100%;
    height: 760px;
}
.sidebar-items a span{
    color: #7000FF;
}