.fs-32px-fw-600{
    font-size: 32px;
    font-weight: 600;
   }
   
   .fs-17-50px{
       font-size: 14.50px;
   }

   .fs-19px{
    font-size: 19px;
}
   .fs-13-5px{
    font-size: 13.5px;
}
   
   .fw-400{
       font-weight: 400;
   }

   .fw-600{
       font-weight: 600;
    }

    .center {
        transform: translate(-50%, -50%);
    }
    
    input.ParticipantsCheck[type="checkbox"] {
        width: 76px;
        height: 38px;
        -webkit-appearance: none;
        background: #F9ECFF;
        outline: none;
        border-radius: 20px;
        transition: 0.7s;
    }
    
    input.ParticipantsCheck[type="checkbox"]:before {
      content: '';
      position: absolute;
      width: 38px;
      height: 38px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #DBDBDB;
      transform: scale(1.1);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      transition: .5s;
    }
    
    input.ParticipantsCheck:checked[type="checkbox"]:before {
      left: 40px;
    }
      
      .blue-header {
        color: #7000ff; /* Optionally change text color */
        font-weight: bold;
        text-align: center;
        word-wrap: break-word;

      }
.active-filter{
    color:#7000FF;
    font-weight: 400;
}
.cursor-na{
  cursor: not-allowed;
}
.actionType{
  color:#343A40;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 10px;
  cursor: pointer;
  border-bottom: 1px solid #DEE2E6;
}
.actionType:hover{
  border: 1px solid #8540F5;
  background:#7749F8;
color: #ffffff;
  transition: all .3s;
}
.active-status{
    /* background-color: #ffe81a; */
    font-family: 'Lexend';
    border-radius: 8px; 
    color:#7000FF;
    padding: 8px 10px;
    font-weight: 700;
}
.cp{
    padding: 8px 10px;
}

.bg-silver{
  background-color: rgba(192, 192, 192, 0.500) !important;
  cursor: no-drop !important;
  color: gray !important;
}

.fs-29px{
  font-size: 29px;
}
.color-purple{
  color: #3A0CA3;
}
.color-light-purple{
  color: #B892D1;
}
.logohaw{
  height: 80px;
  width: 99px;
}
.h-252px{
  height: 252px;
}
.b-shadow{
  box-shadow: 0 0 60px #9dafd371;
}
.color-dark-purple{
  color: #7209B7;
}
.fs-23px{
  font-size: 23px;
}
.fs-26px{
  font-size: 26px;
  font-weight: 600;
}
.sliderModel{
  width: 60%;

}
img.modelunslider-img{
  right: 111px;
}
img.modelunslider-imgTwo{
  right: -102px;
}
.modelunParaRotate{
  transform: rotate(-58deg);
  left: 58%;
}