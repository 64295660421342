.footer {
    background-color: #7000FF;
    color: white;
    font-family: 'Lexend', sans-serif;
    /* background-image: url('../assests/images/footer-bg.jpeg');
    background-size: cover;
    background-position: center; */
}

/* 
.footer {
    filter: grayscale(50%) contrast(130%) brightness(90%);
  } */
.boxOne {
    width: 40%;
}

.box {
    width: 20%;
}

.icons i {
    font-size: 38px;
}

.lowerFooter {
    border-top: 1px solid rgba(240, 240, 240, 0.363);
}

.lowerFooter a {
    font-size: small;
    color: rgb(255, 255, 255) !important;
}

.lowerFooter p {
    font-size: small;
}

.lowerFooter P:first-child {
    width: 40%;
}

a.socialLink {
    color: #fff !important;
}

@media (max-width:768px) {
    .boxOne {
        width: 100%;
    }

    .box {
        width: 100%;
        text-align: center;
    }

    .lowerFooter p:nth-child(1) {
        width: 100%;
    }
}